import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DialogStyled = styled(Dialog)(() => ({}))

export const DialogActionsStyled = styled(DialogActions)(() => ({}))

export const DialogContentStyled = styled(DialogContent)(() => ({}))

export const DialogContentTextStyled = styled(DialogContentText)(() => ({}))

export const DialogTitleStyled = styled(DialogTitle)(() => ({}))
