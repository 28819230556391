import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import LoadingButton from '@mui/lab/LoadingButton'

export const ButtonStyled = styled(Button)(() => ({
  color: 'white',
}))

export const LoadingButtonStyled = styled(LoadingButton)(() => ({
  color: 'white',
}))
