module.exports = Object.freeze({
  postApiMethod: 'post',
  getApiMethod: 'get',
  refreshToken: '/auth/refresh-token',
  login: '/auth/login',

  // project
  listProjects: '/project',
  getProjectByID: '/project',
  createProject: '/project/add',
  editProject: '/project/edit',
  closeProject:'/project/close/',

  // backend team / client users
  listGridBackteam: '/backteam',
  listGridClientUsers: '/backteam/?roleId=3&projectId=',
  getBackteamById: '/backteam',
  createBackteam: '/backteam/add',
  editBackteam: '/backteam/edit',
  editBackteamPassword: '/backteam/key',

  // field team
  listGridFieldTeam: '/fieldteam?projectId=',
  getFieldTeamById: '/fieldteam',
  createFieldTeam: '/fieldteam/add',
  editFieldTeam: '/fieldteam/edit',
  editFieldTeamPassword:'/fieldteam/key',


  // forms
  getFormDefinitions:'/formdefinition/details/project/',
  addFormDefinitions:'/formdefinition/add',
  editFormDefinitions:'/formdefinition/edit',

  // data
  listFormData:'/formdata/project/',
  getFormDataById:'/formdata',

  // export Data
  exportExcel:'formData/excelForm',
  exportPDF:'formdata/exportpdf',

  // ....prev
  deleteBackteam: '/backteam/delete',
  


  // list data (combo box options)
  listOrganizations: '/organization',

  // store
  listGridStore: '/store',
  listStore: '/stores',
  getStoreById: '/store',
  createStore: '/store/add',
  editStore: '/store/edit',
  deleteStore: '/store/delete',
  // .....prev

  // image
  createDocument: '/document/add',

  // ...prev list
  listBackteam: '/backteam',
  listFieldTeam: '/fieldteams',
  listProduct: '/productlist',
  listStoreChannel: '/storechannel',
  listStoreSubChannel: '/storesubchannel',
  listStoreVolume: '/storevolume',
  listStoreAccounts: '/accountstores',
  listAgency: '/agencies',
  listRole: '/roles',
  listCity: '/cities',
  listState: '/states',
  listZone: '/zones',
  listIspTypes: '/isptype',
  listPromoterTypes: '/subrole',
  listProductBrand: '/productbrands',
  listProductCategories: '/productcategories',
  listProductSubCategories: '/productsubcategories',
  listProductVariants: '/productvariants',
  listAttendanceType: '/attendancetype',
  listAttendanceSubType: '/attendancesubtype',
  listAgencyManagement: '/agencymanagement',
  listBranches: '/branch',
  listMerchandisingType: '/merchandisingtype',
  listVisibility: '/visibility',
  listCompetitionBrand: '/competitionbrand',
  listCompetitionCategory: '/competitioncategory',
})
