import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'


// eslint-disable-next-line no-unused-vars, import/prefer-default-export
export const InputStyled = styled(TextField)(({ theme }) => ({
  margin: 1,
}))


