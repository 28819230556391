import { Grid, Button } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import moment from 'moment'

import AQHDropdown from '../../../components/ui/AQHDropdown'
import AQHPickerDate from '../../../components/ui/AQHPicker/date'
import AQHButton from '../../../components/ui/AQHButton'
import {useExportFormDataPDF  } from '../../../hooks/data/useFormData'
import utils from '../../../utilities/Utils'



const validationSchema = yup.object().shape(
  {
    type: yup.object().nullable().required('Type is required')
  }
)

const formOptions = {
  mode: 'onBlur',
  defaultValues: {
    type:''
  },
  shouldUnregister: false,
  resolver: yupResolver(validationSchema),
}
const types = [
  {
    label: 'Export With Image',
    id: 1,
  },
  // {
  //   label: 'Export Without Image',
  //   id: 2,
  // },
]

export default function ExportPDFModal({ toggle, projectId }) {
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const notifyInfo = (toastMessage) => toast.info(toastMessage)
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = useForm(formOptions)

  const watchType = watch('type')
  const onExport= async (data)=>{
    console.log(data)
    notifyInfo('Download initiating.')
    const params = {
      projectId,
      
      startDate: data.type.id===1? moment(data.date).format('YYYY-MM-DD'): moment(data.fromDate).format('YYYY-MM-DD'),
      endDate: data.type.id===1? moment(data.date).format('YYYY-MM-DD'): moment(data.toDate).format('YYYY-MM-DD')
    }
    if(data.type.id===2){
      params.withImage = 0
    }else{
      params.withImage = 1
    }
    try {
      const response = await useExportFormDataPDF(params)
      if (response?.data?.status === 'success') {
        // setExportingPS(false)
        const fileName = response?.data?.data?.fileName?.split('/').at(-1)
        utils.download(response?.data?.data?.fileURL, fileName)
      }
    } catch (error) {
      // console.log(error)
      notify('Error loading data!')
    }
  }

  return (
    <Grid container spacing={1.5} width={{ xs: '250px', sm: '500px' }} component='form' onSubmit={handleSubmit(onExport)}>
      <Grid item xs={12}>
        <AQHDropdown
          control={control}
          name="type"
          options={types}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, optionValue) =>
            optionValue ? option.id === (optionValue?.id || optionValue) : false
          }
          label="Type"
          errors={errors}
          disableClearable
        />
      </Grid>
      {watchType?.id === 1 ? (
        <Grid item xs={12} name="date">
          <AQHPickerDate
            fullWidth
            type="date"
            label="Started On"
            name="date"
            control={control}
            inputFormat="dd/MM/yyyy"
          // minDate={moment('1965-01-01')}
          // maxDate={moment().subtract(18, 'years')}
          />
        </Grid>
      ) : null}

      {watchType?.id === 2 ? (
        <Grid container item xs={12} spacing={1.5}>
          <Grid item xs={12} name="fromDate">
            <AQHPickerDate
              fullWidth
              type="date"
              label="From Date"
              name="fromDate"
              control={control}
              inputFormat="dd/MM/yyyy"
            // minDate={moment('1965-01-01')}
            // maxDate={moment().subtract(18, 'years')}
            />
          </Grid>
          <Grid item xs={12} name="toDate">
            <AQHPickerDate
              fullWidth
              type="date"
              label="To Date"
              name="toDate"
              control={control}
              inputFormat="dd/MM/yyyy"
            // minDate={moment('1965-01-01')}
            // maxDate={moment().subtract(18, 'years')}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12} mx={{ xs: 0.5, sm: 1, md: 3 }} my={1} className="action-buttons">
        <AQHButton
          variant="contained"
          size="medium"
          withLoader
          // loading={dataLoading}
          // disabled={dataLoading || (id ? loadedBit < 3 : loadedBit < 1)}
          type="submit"
        >
          Export
        </AQHButton>

        <Button
          variant="outlined"
          size="medium"
          onClick={toggle}
          sx={{ margin: '20px', fontWeight: '500', fontSize: '12px' }}
        // disabled={dataLoading}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  )
}

ExportPDFModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired
}
