import { useQuery } from 'react-query'
import {
  listOrganizations,
  listProjects,

  // prev................
  listAgencies,
  listCities,
  listRoles,
  listStoreChannels,
  listStates,
  listZones,
  listStoreSubChannels,
  listStoreVolume,
  listProductBrands,
  listAccountStores,
  listProductCategories,
  listProductSubCategories,
  listProductVariants,
  listIspTypes,
  listPromoterTypes,
  listAttendanceType,
  listAttendanceSubType,
  listStores,
  listFieldTeam,
  listAgencyManagement,
  listBranches,
  listMerchandisingType,
  listVisibility,
  listCompetitionBrand,
  listCompetitionCategory,
} from './endPoints'
import { settings } from '../../configs/settingsConfigs'

const { defaultStaleTime } = settings

// .................................

export const useListOrganizations = () =>
  useQuery(['listOrganizations'], () => listOrganizations(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useListProjects = () =>
  useQuery(['listProjectsId'], () => listProjects(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })


// prev...........................................

export const useListAgencies = () =>
  useQuery(['listAgencies'], () => listAgencies(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useListAgencies1 = () =>
  useQuery(['listAgencies1'], () => listAgencies(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
    select: (data) => data?.data?.data?.agencies,
  })

export const useListRoles = () =>
  useQuery(['listRoles'], () => listRoles(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
    // select:(data)=>data?.data?.data?.roles
  })

export const useStoreChannels = () =>
  useQuery(['listStoreChannels'], () => listStoreChannels(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useStoreSubChannels = () =>
  useQuery(['listStoreSeuvChannels'], () => listStoreSubChannels(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useStoreVolumes = () =>
  useQuery(['listStoreVolume'], () => listStoreVolume(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useAccountStores = () =>
  useQuery(['listAccountStores'], () => listAccountStores(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useCities = () =>
  useQuery(['listCities'], () => listCities(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useCities1 = () =>
  useQuery(['listCities1'], () => listCities(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useStates = () =>
  useQuery(['listStates'], () => listStates(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useStates1 = () =>
  useQuery(['listStates1'], () => listStates(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
    select: (data) => data?.data?.data?.states,
  })

export const useZones = () =>
  useQuery(['listZones'], () => listZones(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useProductBrands = () =>
  useQuery(['listProductBrands'], () => listProductBrands(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useProductCategories = () =>
  useQuery(['listProductCategories'], () => listProductCategories(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useProductSubCategories = () =>
  useQuery(['listProductSubCategories'], () => listProductSubCategories(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useProductVariants = () =>
  useQuery(['listProductVariants'], () => listProductVariants(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useIspTypes = () =>
  useQuery(['listIspTypes'], () => listIspTypes(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useIspTypes1 = () =>
  useQuery(['listIspTypes1'], () => listIspTypes(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
    select: (data) => data?.data?.data?.ispType,
  })
export const usePromoterTypes = () =>
  useQuery(['listPromoterTypes'], () => listPromoterTypes(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const usePromoterTypes1 = () =>
  useQuery(['listPromoterTypes'], () => listPromoterTypes(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
    select: (data) => data?.data?.data?.subroles,
  })
export const useAttendanceTypes = () =>
  useQuery(['listAttendanceType'], () => listAttendanceType(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useAttendanceSubTypes = () =>
  useQuery(['listAttendanceSubType'], () => listAttendanceSubType(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useStores = () =>
  useQuery(['listStores'], () => listStores(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })



export const useFieldTeam = () =>
  useQuery(['listFiledTeam'], () => listFieldTeam(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useAgencyManagement = () =>
  useQuery(['listAgencyManagement'], () => listAgencyManagement(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useListBranches = () =>
  useQuery(['listBranches'], () => listBranches(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useListMerchandisingType = () =>
  useQuery(['listMerchandisingType'], () => listMerchandisingType(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
export const useListVisibility = () =>
  useQuery(['listVisibility'], () => listVisibility(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })

export const useListCompetitionBrand = () =>
  useQuery(['listCompetitionBrand'], () => listCompetitionBrand(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
  
export const useListCompetitionCategory = () =>
  useQuery(['listCompetitionCategory'], () => listCompetitionCategory(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  })
