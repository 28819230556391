import Home from '../views/home/Home'
import { Roles } from '../auth'
import SignIn from '../views/signin/SignIn'
import Error404 from '../views/errors/Error404'
import ErrorGeneral from '../views/errors/ErrorGeneral'
import Unauthorized from '../views/errors/Unauthorized'
import Projects from '../views/projects/Projects'
import CreateProjectRoot from '../views/projects/CreateProjectRoot'
import Data from '../views/data/Data'
// import TestQuiz from '../views/test/TestQuiz'


// eslint-disable-next-line import/prefer-default-export
export const publicRoutes = [
  {
    path: '/',
    exact: true,
    auth: [],
    component: Home,
    protected: true, // will allow to protect route and make is accessible for logged in Users only
  },
  {
    path: '/signin',
    exact: true,
    auth: [],
    component: SignIn,
    protectedNoAuth: true, // will allow to protect route and make is not accessible when already logged in
  },
  {
    path: '*',
    exact: true,
    auth: [],
    component: Error404,
    protected: false, // For all public pages like about us, blogs etc
  },
  {
    path: '/error',
    exact: true,
    auth: [],
    component: ErrorGeneral,
    protected: true, // For all public pages like about us, blogs etc
  },

  // projects
  {
    path: '/app/projects',
    exact: true,
    auth: [Roles.sysconadmin, Roles.organizationadmin, ],
    component: Projects,
    protected: true,
  },
  {
    path: '/app/projects/add',
    exact: true,
    auth: [Roles.sysconadmin, Roles.organizationadmin, ],
    component: CreateProjectRoot,
    protected: true,
  },
  {
    path: '/app/projects/edit/:id',
    exact: true,
    auth: [Roles.sysconadmin, Roles.organizationadmin, ],
    component: CreateProjectRoot,
    protected: true,
  },

  // data
  {
    path: '/app/data',
    exact: true,
    auth: [Roles.sysconadmin, Roles.organizationadmin, Roles.projectmanagement],
    component: Data,
    protected: true,
  },

  // utils

  // ...
  {
    path: '/unauthorized',
    exact: true,
    auth: [],
    component: Unauthorized,
    protected: false,
  },
  // {
  //   path: '/test-quiz',
  //   exact: true,
  //   auth: [],
  //   component: TestQuiz,
  //   protected: false,
  // },
]

