import React from 'react'
// eslint-disable-next-line import/no-unresolved
import AppContext from 'context/AppContext'
import { Provider } from 'react-redux'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enLocale from 'date-fns/locale/en-IN'

import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClientProvider, QueryClient } from 'react-query'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'

// import Loader from './components/ui/loader/loader'

import theme from './assets/theme' // Default themes
import themeDark from './assets/theme-dark' // Dark Mode themes

import { store, persistor } from './store'
import 'react-toastify/dist/ReactToastify.css'

import Router from './routes'

// dark mode not implemented
const darkMode = false

// import TestingSetup from "./utilities/testingSetup";

if (process.env.NODE_ENV === 'development') {
  // TestingSetup();
}

const queryClient = new QueryClient()

function App() {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{}}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <ThemeProvider theme={darkMode ? themeDark : theme}>
              <CssBaseline />
              <QueryClientProvider client={queryClient}>
                {/* <React.Suspense fallback={<Loader/>}> */}
                  {/* As we are using Lazy loading while creating routes need to have this fallback.Please style it accordingly */}
                  <Router />
                {/* </React.Suspense> */}
                <ToastContainer className="toast-container-responsive" />
              </QueryClientProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  )
}

export default App
