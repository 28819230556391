import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormLabel, FormHelperText } from '@mui/material'
import controlWrapper from '../../controlWrapper'
import {
  RadioGroupStyled,
  RadioStyled,
  FormControlLabelStyled,
  CheckboxStyled,
} from './SelectiveInputStyled'

function Index(props) {
  const { name, label, size, margin, variant, labelPlacement, required, onChange, error, value, type, options, disabled, row, ...rest } =
    props

  const control =
    type === 'checkbox' ? (
      <CheckboxStyled onChange={onChange} checked={value} />
    ) : (
      <RadioStyled />
    )
  let formControlContent
  if (type === 'checkbox') {
    formControlContent = (
      <FormControlLabelStyled disabled={disabled} control={control} label={label} labelPlacement={labelPlacement}  />
    )
  }
  if (type === 'radio') {
    formControlContent = (
      <RadioGroupStyled onChange={onChange} value={value} row={row}>
        {options.map((option) => (
          <FormControlLabelStyled
            key={option.label}
            value={option.value}
            disabled={disabled}
            control={control}
            label={option.label}
          />
        ))}
      </RadioGroupStyled>
    )
  }
  const labelId = `${name}-label`
  return (
    <FormControl {...rest} error={!!error} required={required} margin={margin} variant={variant} >
      {type === 'radio' && <FormLabel id={labelId}>{label}</FormLabel>}
      {formControlContent}
      <FormHelperText size='large' sx={{ root: { color: '#ff0000' }}} error={!!error}>
        {error ? error.message : null}
      </FormHelperText>
    </FormControl>
  )
}

Index.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['none', 'normal', 'dense']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  labelPlacement: PropTypes.oneOf(['top', 'bottom', 'start', 'end']),
  error: PropTypes.any,
  size: PropTypes.oneOf(['medium', 'small', PropTypes.string]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  row: PropTypes.bool,
  required: PropTypes.bool,
}

Index.defaultProps = {
  size: 'small',
  margin: 'dense',
  variant: 'outlined',
  disabled: false,
  options: [],
  error: '',
  row: true,
  required: false,
  labelPlacement: 'end'
}
const AQHSelectiveInput = controlWrapper(Index)
export default AQHSelectiveInput
