// grid list filtering options for different data types
export const filterLimits = [
  //  the operators name are according to reactdatagrid
  { string: ['contains', 'eq', 'startsWith'] },
  { customDate: ['afterOrOn', 'beforeOrOn', 'eq', 'inrange'] },
  { number: ['gte', 'lte', 'eq'] },
]

// exporting pdf settings
export const exportPdfSettings = {
  headingBackgroundColor: '#3498db',
  pdfFooterText: '[#] This is a system generated document by DHANUSH.',
}

// used in grid lists as id for hooks and reactdatagrid
export const gridListTypes = {
  // project
  projects: 'projects',
  clientUsers: '/backteam',
  fieldTeam: '/fieldteam',
  // formData
  formData: '/formdata',
  // ....prev
  backTeam: 'userlist',
  stores: 'storelist',
}

const gridListConfigs = {
  filterLimits,
  exportPdfSettings,
  gridListTypes,
}

export default gridListConfigs
