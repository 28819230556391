import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import AQHTextfield from '../../../components/ui/AQHInput'
import AQHDropdown from '../../../components/ui/AQHDropdown'
import AQHSelectiveInput from '../../../components/ui/AQHSelectiveInput'
import AQHButton from '../../../components/ui/AQHButton'
import { projectFormSettings } from '../../../configs/formSettings'

const { questionTypesOptions, formTypeConfigs } = projectFormSettings

const validationSchema = yup.object().shape({
  question: yup.string().required('Question Label is required'),
  questionType: yup.object().nullable().required('Question Type is required'),
  mandatory: yup.bool().required('Mandatory is required'),
})

const defaultValues = {
  question: '',
  questionType: '',
  mandatory: false,
}

const formOptions = {
  mode: 'onBlur',
  defaultValues,
  shouldUnregister: false,
  resolver: yupResolver(validationSchema),
}

function CreateFormModal({ toggle, setData, questionData }) {
  const [dataLoading, setDataLoading] = useState(false)
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = (data) => {
    if (questionData.find((e) => e.label === data.question)) {
      notify('Question already added')
      return
    }

    const qType = data.questionType.inputSubType
    const tmpDef = {
      label: data.question,
      inputSubType: qType,
      mandatory: data.mandatory,
      ...formTypeConfigs[qType],
    }
    setData((s) => [...s, { ...tmpDef }])
    notifySuccess('Question added')
    toggle()
  }

  return (
    <Box sx={{ width: { md: '500px', xs: '50vw' } }}>
      <form style={{ flex: 1 }} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={1} marginTop={1} justifyContent="center">
          <Grid item xs={12}>
            <AQHTextfield
              name="question"
              control={control}
              label="Question"
              margin="dense"
              errors={errors}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={12}>
            <AQHDropdown
              control={control}
              name="questionType"
              options={questionTypesOptions}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, optionValue) =>
                optionValue
                  ? option.inputSubType === (optionValue?.inputSubType || optionValue)
                  : false
              }
              label="Type"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} name="mandatory">
            {/* Gender */}
            <AQHSelectiveInput
              type="radio"
              control={control}
              name="mandatory"
              label="Mandatory"
              errors={errors}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              sx={{
                width: '100%',
                display: 'flex',
                gap: '20px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            />
          </Grid>

          <Grid item xs={12} className="action-buttons" marginTop={1}>
            <AQHButton
              variant="contained"
              size="medium"
              type="submit"
              withLoader
              loading={dataLoading}
              disabled={dataLoading}
            >
              Add
            </AQHButton>
            <Button variant="outlined" disabled={dataLoading} onClick={() => toggle()}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
CreateFormModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  questionData: PropTypes.array.isRequired,
}
export default CreateFormModal
