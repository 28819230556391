import React from 'react'
import { Typography, Stack, Box, Button } from '@mui/material'
import Error1 from '../../assets/icons/error1.png'
import Error2 from '../../assets/icons/error2.png'
import Constants from '../../utilities/Constants'

// import { Navbar } from '../../layouts/navbar/Navbar'
// import { AppBarStyled, ToolBarStyled } from '../../layouts/navbar/NavbarStyle'

function ErrorGeneral() {
  return (
    <Stack height="80vh" sx={{ position: 'relative', backgroundColor: 'white' }}>
      <Box sx={{ position: 'absolute', left: { md: '30px', sm: '20px', xs: '10px' } }}>
        <img src={Error2} alt="error" width="200px" className="error404-logo1" />
      </Box>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Stack direction="row" alignItems="center">
          <img src={Error1} alt="error" width="100px" className="error404-logo2" />
          <Typography variant="h4" p={2} sx={{ fontSize: { xs: 16, sm: 24, md: 32 } }}>
            Something went wrong :(
          </Typography>
        </Stack>
        <Box>
          <Button variant="outlined" href={Constants.ROUTE_INDEX_URL}>
            Dashboard
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}

export default ErrorGeneral
