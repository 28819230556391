import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Grid, Typography, Button } from '@mui/material'
import { useSelector } from 'react-redux'

import useTabs from '../../hooks/useTabs'
import { configs } from '../../assets/theme/base/globals'
import CreateProject from './CreateProject'
import ClientUsers from './clientUsers/ClientUsers'
import Form from './form'
import FieldTeam from './fieldTeam/FieldTeam'
// import { projectState } from '../../store/projectSlice'
import Constants from '../../utilities/Constants'

function CreateProjectRoot() {
  const { mainAreaIn100vh } = configs
  const { id } = useParams()
  // const {projectName} = useSelector(projectState)
  const [projectName, setProjectName] = useState(null)
  const navigate =useNavigate()

  const tabContents = [
    <CreateProject setProjectName={setProjectName} />,
    <ClientUsers />,
    <FieldTeam />,
    <Form />,
  ]
  const { enableTab, TabElement } = useTabs({
    tabs: [
      { name: 'Project Details' },
      { name: 'Client Users' },
      { name: 'Field Team' },
      { name: 'Form' },
    ],
    tabContents,
    disabledTabs: ['Client Users', 'Field Team', 'Form'],
  })

  useEffect(() => {
    if (id) {
      enableTab(['Client Users', 'Field Team', 'Form'])
    }
  }, [id])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',

        minHeight: mainAreaIn100vh,
      }}
    >
      <Grid
        width={{ xs: '85vw', md: '70vw' }}
        p={3}
        mb={2}
        bgcolor="bg.main"
        sx={{ borderRadius: '10px', border: '1px solid gray' }}
      >
        <Box display='flex' justifyContent='space-between'>
        <Typography variant="h4">
          {!id ? 'Create Project' : projectName ?? 'Edit Project'}
        </Typography>
        <Button
            variant="outlined"
            size="medium"
            onClick={() => navigate(Constants.ROUTE_PROJECTS)}
            sx={{ margin: '20px', fontWeight: '500', fontSize: '12px' }}
            // disabled={dataLoading}
          >
            Back
          </Button>
        </Box>
        <TabElement />
      </Grid>
    </Box>
  )
}

export default CreateProjectRoot
