import { useEffect } from 'react'
import { IconButton, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { authUser } from '../../store/authUserSlice'
import { configs } from '../../assets/theme/base/globals'
import { checkUserSession } from '../../utilities/axiosUtils'
import { Roles } from '../../auth'
import Constants from '../../utilities/Constants'


function Home() {
  const navigate = useNavigate()
  const { mainAreaIn100vh } = configs
  checkUserSession({ url: '/' })
  const loggedInUser = useSelector(authUser)
  useEffect(()=>{
    if(loggedInUser.role===Roles.organizationadmin || loggedInUser.role===Roles.sysconadmin)
    navigate(Constants.ROUTE_PROJECTS,{replace:true})
    if(loggedInUser.role===Roles.projectmanagement)
    navigate(Constants.ROUTE_DATA,{replace:true})
    // default route for other roles
    // navigate(Constants.ROUTE_PROJECTS,{replace:true})
  },[])

  return (
    <Box minHeight={mainAreaIn100vh} sx={{ paddingY: 3, paddingX: 5 }} className='center-full'>
      <Typography variant='h4'>Home</Typography>
    </Box>
  )
}

export default Home
