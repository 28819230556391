import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { ButtonStyled, LoadingButtonStyled } from './StyledButton'

function AQHButton(props) {
  const {
    size,
    variant,
    withLoader,
    loading,
    fullWidth,
    disabled,
    loadingPosition,
    onClick,
    children,
    type,
    disableElevation,
    ...rest
  } = props
  const Element = withLoader ? LoadingButtonStyled : ButtonStyled

  return (
    <Element
      size={size}
      type={type}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      loadingPosition={loadingPosition}
      loading={loading}
      {...rest}
    >
      {children}
    </Element>
  )
}

AQHButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  loading: PropTypes.bool,
  withLoader: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingIndicator: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  onClick: PropTypes.func,
  loadingPosition: PropTypes.oneOf(['start', 'end', 'center']),
  fullWidth: PropTypes.bool,
  disableElevation: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.oneOf(['button', 'submit']),
}

AQHButton.defaultProps = {
  size: 'small',
  loading: false,
  withLoader: true,
  variant: 'contained',
  disableElevation: true,
  disabled: false,
  onClick: () => {},
  loadingPosition: 'center',
  loadingIndicator: <CircularProgress color="inherit" size={16} />,
  fullWidth: false,
  children: 'Button',
  type: 'submit',
}

export default AQHButton
