import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Box, Grid, Typography, Divider, CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import moment from 'moment'

import AQHTextField from '../../components/ui/AQHInput'
import AQHPickerDate from '../../components/ui/AQHPicker/date'
import useGetFormDataByID from '../../hooks/data/useFormData'
import GoogleMap from '../../components/utils/GooglMap'
import { formGeneralSettings } from '../../configs/formSettings'
import AQHUpload from '../../components/ui/AQHUpload'
import Constants from '../../utilities/Constants'

function DataModal({ id }) {
  const { data: formData, isLoading: formDataLoading } = useGetFormDataByID({ formDataId: id })
  const [formUtilities, setFormUtilities] = useState({ location: null, formDataDetails: [] })
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const [imageState, setImageState] = useState({})

  // console.log(formData)
  const formOptions = {
    mode: 'onBlur',
    defaultValues: {},
    shouldUnregister: false,
  }

  const {
    control,
    formState: { errors },
    reset,
  } = useForm(formOptions)

  // formDataDetails helper functions
  const formatLabel = (q) => q?.split(' ').join('')
  const formatFormDetails = (data) =>
    data.reduce(
      (a, e) => ({ ...a, [formatLabel(e.question)]: e.inputType === 'Image' ? 'Image' : e.value }),
      {},
    )

  const mapLinkHandler =
    formUtilities.location?.latitude && formUtilities.location?.longitude
      ? `${formGeneralSettings.viewOnMapURL}${formUtilities.location?.latitude},${formUtilities.location?.longitude}`
      : `${formGeneralSettings.viewOnMapFallBackURL}`
  // .............

  useEffect(() => {
    if (formDataLoading === false) {
      if (formData?.data?.status !== 'success') {
        notify(formData?.data?.message || 'Error loading form data !')
      } else {
        const fData = formData?.data?.data?.formData

        setFormUtilities({
          location: { latitude: fData.latitude, longitude: fData.longitude },
          formDataDetails: fData.formDataDetails,
        })
        const editValues = {
          organizationName: fData.organizationName,
          projectName: fData.projectName,
          brand: fData.brand,
          client: fData.client,
          userName: fData.userName,
          entryOn: moment(fData.entryOn),
          latitude: (+fData.latitude).toFixed(6),
          longitude: (+fData.longitude).toFixed(6),
          ...formatFormDetails(fData.formDataDetails),
        }
        reset({ ...editValues })
      }
    }
  }, [formDataLoading])

  return (
    <Grid
      container
      sx={{ opacity: formDataLoading ? 0.5 : 1 }}
      minWidth={{ xs: '250px', sm: '500px', md: '600px' }}
      spacing={1.5}
    >
      {formDataLoading ? (
        <CircularProgress sx={{ position: 'absolute', left: '45%', top: '45%' }} />
      ) : null}
      <Grid item xs={12}>
        <AQHTextField
          name="projectName"
          control={control}
          label="Project Name"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <AQHTextField
          name="organizationName"
          control={control}
          label="Organization Name"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <AQHTextField
          name="client"
          control={control}
          label="Client"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <AQHTextField
          name="brand"
          control={control}
          label="Brand"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <AQHTextField
          name="userName"
          control={control}
          label="User Name"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <AQHTextField
          name="latitude"
          control={control}
          label="Latitude"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <AQHTextField
          name="longitude"
          control={control}
          label="Longitude"
          margin="normal"
          maxLength={10}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12} mb={1.5}>
        <GoogleMap mapLinkHandler={mapLinkHandler} />
      </Grid>
      <Grid item xs={12}>
        <AQHPickerDate
          fullWidth
          type="date"
          label="Entry On"
          name="entryOn"
          control={control}
          inputFormat="dd/MM/yyyy"
          // inputProps={{readOnly:true}}
          readOnly
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Form </Typography>
      </Grid>
      {formUtilities.formDataDetails?.map((e) => (
        <Grid key={e.question} item xs={12}>
          <AQHTextField
            name={formatLabel(e?.question)}
            control={control}
            label={e.question}
            margin="normal"
            maxLength={10}
            errors={errors}
            inputProps={{ readOnly: true }}
          />
          {e.inputType === 'Image' ? (
            <Box marginY={1}>
              <AQHUpload
                downloadable
                state={{
                  previewImage: e.value ? `${Constants.FILE_URL}${e.value}` : null,
                  remoteImageFile: `${Constants.FILE_URL}${e.value}`,
                  imageFile: e.value,
                }}
                setState={() => null}
                editable={false}
              />
            </Box>
          ) : null}
        </Grid>
      ))}
    </Grid>
  )
}

DataModal.propTypes = {
  id: PropTypes.number.isRequired,
}
export default DataModal
