/**
 *  Roles
 */
const Roles = {
  sysconadmin: 'Syscon Admin',
  organizationadmin: 'Organization Admin',
  projectmanagement:'Project Management',
  // ....................................................
  admin: 'admin',
  clientadmin: 'Client Admin',
  clientbrandmanager: 'Client Brand Manager',
  clientbrandassistant: 'Client Brand Assistant',
  clientbrandexecutive: 'Client Brand Executive',
  agencymanagement: 'Agency Management',
  agencyreagional: 'Agency Regional Manager',
  agencymis: 'Agency MIS',
  regionalsalesmanager: 'Regional Sales Manager',
  areasalemanager: 'Area Sales Manager',
  assitantsalesmanager: 'Assitant Sales Manager',
  promoter: 'Promoter',
  supervisor: 'Supervisor',
  assistantsupervisor: 'Assistant Supervisor',
  guest: 'guest',
}

export default Roles
