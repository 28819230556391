import React from 'react'
import { Button, Box, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { NavbarNoAuth } from '../../layouts/navbar/NavbarNoAuth'
import Image404 from '../../assets/icons/404.jpg'
import { configs } from '../../assets/theme/base/globals'

const { mainAreaIn100vh } = configs

function Error404() {
  const navigate = useNavigate()
  return (
    <Box
      sx={{ position: 'relative', top: '64px' }}
      className="center-full"
      minHeight={mainAreaIn100vh}
    >
      <NavbarNoAuth />

      <Stack>
        <Box component="img" src={Image404} width={700} />
        <Box className="center-width">
          <Button variant="outlined" size="large" onClick={() => navigate('/')}>
            Dashboard
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}

export default Error404
