import React from 'react'
import { Typography } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import Constants from 'utilities/Constants'
import { AppBarStyled, ToolBarStyled } from './NavbarStyle'

// eslint-disable-next-line import/prefer-default-export, react/prop-types
export function NavbarNoAuth() {
  const navigate = useNavigate()
  return (
    <AppBarStyled
      position="fixed"
      elevation={0}
      sx={{ height: '64px', backgroundColor: '#dce3e6' }}
    >
      <ToolBarStyled>
        <IconButton
          color="inherit"
          aria-label="Dhanush home"
          onClick={() => navigate(Constants.ROUTE_INDEX_URL, { replace: true })}
          edge="start"
        >
          <img alt="logo" src="/B2PLiveLogoTransparentCropped.png" style={{ height: '48px' }} />
        </IconButton>
        <Typography variant="h4" color="#195682">
          BTP Live
        </Typography>
        {/* empty element for ui */} <Typography />
      </ToolBarStyled>
    </AppBarStyled>
  )
}
