import { useQuery, useMutation } from 'react-query'
import {
  listBackteam,
  getBackteamById,
  addBackteam,
  createDocument,
  editBackteam,
  editBackteamPassword,
  deleteBackteam,
} from '../endPoints'

export const useValidateListUser = () => useQuery(listBackteam)

export const useGetUserById = ({ userId }) =>
  useQuery(['getUserById', userId], () => getBackteamById({ userId }), {
    refetchOnWindowFocus: false,
  })

// eslint-disable-next-line import/prefer-default-export
export const useValidateAddUser = () => useMutation(addBackteam)
export const useEditBackteam = () => useMutation(editBackteam)
export const useDeleteBackteam = () => useMutation(deleteBackteam)
export const useAddDocument = () => useMutation(createDocument)
export const useEditBackteamPassword = () => useMutation(editBackteamPassword)
