// eslint-disable-next-line no-unused-vars
import { styled, useTheme } from '@mui/material/styles'
// eslint-disable-next-line import/no-unresolved
import { configs } from 'assets/theme/base/globals'



export const MainArea = styled('main', {})(
  ({ theme}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
   
    marginTop: '114px',
    backgroundColor: '#fff',
  }),
)

// eslint-disable-next-line no-unused-vars
export const ContentArea = styled('div')(({ theme }) => ({
  flex: 1,
  alignItems: 'left',
  justifyContent: 'flex-start',
  backgroundColor: '#ffffff',
}))

// eslint-disable-next-line no-unused-vars
export const FooterArea = styled('div')(({ theme }) => ({
  flex: 1,
}))


