import { toast, Slide } from 'react-toastify'

import Constants from './Constants'
import { store } from '../store'
import { removeUser } from '../store/authUserSlice'

export default {
  /** Function to show toast */
  showToast: (description, type, position = Constants.POSITION_TOAST_TOP_RIGHT) => {
    const toastOptions = {
      position,
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type,
      theme: 'colored',
      transition: Slide,
      style: { fontSize: '13px' },
    }
    // Showing the toast
    toast(description, toastOptions)
  },

  // function to return errors in (make condition to return error message based on error code)
  // eslint-disable-next-line consistent-return
  returnErrorMsg: (errorResponse) => {
    if (
      errorResponse.status === Constants.STATUS_BAD_REQUEST ||
      errorResponse.status === Constants.STATUS_UNAUTHORIZED
    ) {
      return errorResponse.data
    }
    return Constants.ERROR_SOMETHING_WRONG
  },

  HashString: (str, seed = 0) => {
    // uses cyber53 algol
    // eslint-disable-next-line no-bitwise
    let h1 = 0xdeadbeef ^ seed
    // eslint-disable-next-line no-bitwise
    let h2 = 0x41c6ce57 ^ seed
    // eslint-disable-next-line no-plusplus
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      // eslint-disable-next-line no-bitwise
      h1 = Math.imul(h1 ^ ch, 2654435761)
      // eslint-disable-next-line no-bitwise
      h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    // eslint-disable-next-line no-bitwise
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    // eslint-disable-next-line no-bitwise
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
    // eslint-disable-next-line no-bitwise
    return 4294967296 * (2097151 & h2) + (h1 >>> 0)
  },

  checkUserSessionTimeOut: (curTime) => {
    const localLastTimeStamp = localStorage.getItem('lastActiveTimeStamp')
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(localLastTimeStamp)) {
      localStorage.clear()
      store.dispatch(removeUser())
      return true
    }

    // eslint-disable-next-line no-else-return
    else if (Number(curTime) - Number(localLastTimeStamp) > Constants.USER_SESSION_TIMEOUT) {
      localStorage.clear()
      store.dispatch(removeUser())
      return true
    } else {
      return false
    }
  },
  // download func
  download: (path, filename) => {
    const anchor = document.createElement('a')
    anchor.href = path
    anchor.download = filename
    anchor.target="_blank"

    anchor.style.display = 'none'
    document.body.appendChild(anchor)

    anchor.click()

    document.body.removeChild(anchor)
  },
}
