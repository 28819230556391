import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography, Button, CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import AQHButton from '../../components/ui/AQHButton'
import AQHDropdown from '../../components/ui/AQHDropdown'
import AQHPickerDate from '../../components/ui/AQHPicker/date'
import AQHTextField from '../../components/ui/AQHInput'
import AQHSelectiveInput from '../../components/ui/AQHSelectiveInput'
import { useAddProject, useGetProjectByID, useEditProject } from '../../hooks/data/useProjectData'
import Constants from '../../utilities/Constants'
import { formGeneralSettings } from '../../configs/formSettings'
import { configs } from '../../assets/theme/base/globals'
import { setProjectDetails } from '../../store/projectSlice'

const validationSchema = yup.object().shape({
  projectName: yup
    .string()
    .required('Project Name is required')
    .max(100, 'Project Name must be less than or equal to 100 characters'),
  client: yup
    .string()
    .required('Client is required')
    .max(100, 'Client must be less than or equal to 100 characters'),
  brand: yup
    .string()
    .required('Brand is required')
    .max(100, 'Brand must be less than or equal to 100 characters'),
  projectManager: yup
    .string()
    .required('Project Manager is required')
    .max(100, 'Project Manager must be less than or equal to 100 characters'),
  team: yup.object().nullable(),
  startDate: yup.date().required('Start Date is required').typeError('Start Date is required'),
  endDate: yup.date().nullable(),
})

const defaultValues = {
  projectName: '',
  client: '',
  brand: '',
  organization: '',
  projectManager: '',
  startDate: null,
  endDate: null,
}

const formOptions = {
  mode: 'onBlur',
  defaultValues,
  shouldUnregister: false,
  resolver: yupResolver(validationSchema),
}

function CreateProject({ setProjectName }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [organizations, setOrganizations] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const { mainAreaIn100vh } = configs

  const { mutateAsync: addProject } = useAddProject()
  const { mutateAsync: editProject } = useEditProject()

  const [loadedBit, setLoadedBit] = useState(0)
  const [projectEnded, setProjectEnded] = useState(false)
  const teams = [
    {
      teamName: 'Alpha',
      teamId: 1,
    },
    {
      teamName: 'Seals',
      teamId: 2,
    },
    {
      teamName: 'NBD',
      teamId: 3,
    }
  ]

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
  } = useForm(formOptions)

  // edit mode
  const { id } = useParams()
  const { data: projectData, isLoading: projectDataLoading } =
    id !== undefined && id > 0
      ? useGetProjectByID({ projectID: id })
      : { data: null, isLoading: false }

  useEffect(() => {
    if (projectDataLoading === false && projectData?.data?.status !== 'success') {
      // display a notice
      if (id) {
        notify('Error in Project Data Loading')
      }
    } else {
      if (projectData?.data?.data?.project === undefined) return

      const pData = projectData?.data?.data?.project
      console.log(pData)
      // updating organization id for project in redux store
      dispatch(
        setProjectDetails({
          organizationId: pData.organizationId,
          projectId: pData.projectId,
          // projectName: pData.projectName,
        }),
      )
      setProjectName(pData.projectName)
      // ...

      const endDateCheck = moment(pData.endDate).isBefore(moment('2050-01-01'))
        ? moment(pData.endDate)
        : null
      const editValues = {
        projectName: pData.projectName,
        // projectCode: pData.projectCode,
        client: pData.client ?? '',
        brand: pData.brand ?? '',
        projectManager: pData.projectManager ?? '',
        // projectDescription: '',
        team: teams.find((e) => e.teamName === pData.team),
        startDate: moment(pData.startDate),
        endDate: endDateCheck,
      }
      if (endDateCheck) {
        setProjectEnded(true)
      }

      // console.log(editValues)
      reset({ ...editValues })
      setLoadedBit((s) => s | 2) // eslint-disable-line no-bitwise
    }
  }, [projectData])



  const onSubmitForm = async (data) => {
    // console.log(data)
    setDataLoading(true)

    const params = {
      projectName: data.projectName,
      // projectCode: data.projectCode,
      projectCode: '',
      client: data.client,
      brand: data.brand,
      projectManager: data.projectManager,
      projectDescription: '',
      // organization name -> team
      organizationId: 2,
      team: data.team.teamName,
      startDate: moment(data.startDate).format('YYYY-MM-DD'),
      endDate: data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : null,
      ProjectGUID: '527e7ab6-1969-4368-a29a-0f67af34a015',
    }
    console.log(params)
    // edit or save
    if (id) {
      // edit
      await editProject(
        { ...params, projectId: id },
        {
          // eslint-disable-next-line no-shadow
          onSuccess: (response) => {
            setDataLoading(false)

            // eslint-disable-next-line no-prototype-builtins
            // console.log(response)
            if (!response) {
              notify('Something went wrong !')
              return
            }
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('error')) {
              notify(response.data.error.message)
            } else {
              notifySuccess(response.data.message)
              navigate(Constants.ROUTE_PROJECTS, { replace: true })
            }
          },
          onError: (error) => {
            notify(error.getMessage())
          },
        },
      )
    } else {
      // add
      await addProject(params, {
        // eslint-disable-next-line no-shadow
        onSuccess: (response) => {
          // eslint-disable-next-line no-prototype-builtins
          // console.log(response)
          if (!response) {
            notify('Something went wrong !')
            return
          }
          setDataLoading(false)
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.hasOwnProperty('error')) {
            notify(response.data.error.message)
          } else {
            notifySuccess(response.data.message)
            // window.location.href=`${Constants.ROUTE_PROJECTS_EDIT}/${response.data.data.projectId}`
            navigate(`${Constants.ROUTE_PROJECTS_EDIT}/${response.data.data.projectId}`,{replace:true})
          }
        },
        onError: (error) => {
          notify(error.getMessage())
        },
      })
    }

    setDataLoading(false)
  }

  // scroll on error
  useEffect(() => {
    if (errors) {
      // const errorsValues = Object.values(errors)
      // if (errorsValues.length > 0) {
      //   const firstErrorElement = document.getElementsByName(errorsValues[0].ref?.name)[0]
      //   firstErrorElement.scrollIntoView({ behavior: `smooth`, block: 'center' })
      //   firstErrorElement?.focus()
      //   if (Object.keys(dirtyFields).length > 0) {
      //     notify('Form contains some error or empty fields !')
      //   }
      // }
      console.log(errors)
    }
  }, [errors])

 
  //   setDataDeleteLoading(true)
  //   console.log('#[simulation] deleting ', id)
  //   await deleteStore(
  //     { id },
  //     {
  //       // eslint-disable-next-line no-shadow
  //       onSuccess: (response) => {
  //         setDataDeleteLoading(false)
  //         // eslint-disable-next-line no-prototype-builtins
  //         console.log(response)
  //         if(!response){
  //           notify('Something went wrong !')
  //           return
  //         }
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.data.hasOwnProperty('error')) {
  //           notify(response.data.error.message)
  //         } else {
  //           notifySuccess(response.data.message)
  //           navigate(Constants.ROUTE_STORE_LIST,{ replace: true })
  //           setDeleteDialogOpen(false)
  //         }
  //       },
  //       onError: (error) => {
  //         notify(error.getMessage())
  //         setDataDeleteLoading(false)
  //       },
  //     },
  //   )
  // }

  // ...

  return (
    <Box className="center-width" minHeight={mainAreaIn100vh}>
      <Grid
        container
        spacing={1.5}
        marginTop={2}
        p={3}
        pt={0}
        sx={{ borderRadius: '10px', backgroundColor: 'bg.main', position:'relative' }}
        component="form"
        onSubmit={handleSubmit(onSubmitForm)}
      // autoComplete="off"
      >
        {projectDataLoading ? (
          <CircularProgress
            color='primary'
            sx={{
              position: 'absolute',
              top: '45%',
              left: '47%',
            }}
          />
        ) : null}
        <Grid item xs={12}>
          <AQHTextField
            name="projectName"
            control={control}
            label="Project Name"
            margin="normal"
            maxLength={100}
            errors={errors}
          // autoComplete="projectName"
          />
        </Grid>
        <Grid item xs={12}>
          <AQHTextField
            name="client"
            control={control}
            label="Client"
            margin="normal"
            maxLength={100}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <AQHTextField
            name="brand"
            control={control}
            label="Brand"
            margin="normal"
            maxLength={100}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <AQHTextField
            name="projectManager"
            control={control}
            label="Project Manager"
            margin="normal"
            maxLength={100}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <AQHDropdown
            control={control}
            name="team"
            options={teams}
            getOptionLabel={(option) => option.teamName || ''}
            isOptionEqualToValue={(option, optionValue) =>
              optionValue
                ? option.teamId === (optionValue?.teamId || optionValue)
                : false
            }
            label="Team"
            errors={errors}
            disabled={!!id}
          />
        </Grid>

        <Grid item xs={12} name="startDate">
          <AQHPickerDate
            fullWidth
            type="date"
            label="Started On"
            name="startDate"
            control={control}
            inputFormat="dd/MM/yyyy"
            // minDate={moment('1965-01-01')}
            // maxDate={moment().subtract(18, 'years')}
            disabled={projectEnded}
          />
        </Grid>

        {id ? (
          <Grid item xs={12} name="endDate">
            <AQHPickerDate
              fullWidth
              type="date"
              label="Ended On"
              name="endDate"
              control={control}
              inputFormat="dd/MM/yyyy"
              // minDate={moment('1965-01-01')}
              // maxDate={moment().subtract(18, 'years')}
              disabled
            />
          </Grid>
        ) : null}

        {/* ... */}
        <Grid item xs={12} mx={{ xs: 0.5, sm: 1, md: 3 }} my={1} className="action-buttons">
          <AQHButton
            variant="contained"
            size="medium"
            withLoader
            loading={dataLoading}
            // disabled={dataLoading || (id ? loadedBit < 3 : loadedBit < 1)}
            type="submit"
          >
            Save
          </AQHButton>

          {/* <Button
            variant="outlined"
            size="medium"
            onClick={() => navigate(Constants.ROUTE_PROJECTS)}
            sx={{ margin: '20px', fontWeight: '500', fontSize: '12px' }}
            disabled={dataLoading}
          >
            Back
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  )
}

CreateProject.propTypes = {
  setProjectName: PropTypes.func.isRequired,
}

export default CreateProject
