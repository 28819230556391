import { useState } from 'react'
import { Typography, Grid, IconButton, Stack } from '@mui/material'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { useNavigate } from 'react-router-dom'

import useData from '../../hooks/data/useGridData'
import ErrorFetching from '../errors/ErrorFetching'
import ProgressLoading from '../../components/ui/listprogress/ProgressLoading'
import ListView from '../../components/ui/listview/AQHListView'
import AQHButton from '../../components/ui/AQHButton'
import { gridListTypes } from '../../configs/gridListSettings'
import Constants from '../../utilities/Constants'
import useModal from '../../hooks/useModal'
import Modal from '../../components/ui/modal/Modal'
import EndProjectModal from './EndProjectModal'

const testData = [
  {
    id: 1,
    projectName: 'Project A',
    projectCode: 'ABSD145',
    client: 'CLient 1A',
    brand: 'Brand T',
    projectManager: 'Test Name',
    startDate: '2020-01-03',
    endDate: null,
  },
  {
    id: 2,
    projectName: 'Project B',
    projectCode: 'ABSD146',
    client: 'CLient 1B',
    brand: 'Brand T2',
    projectManager: 'Test Name2',
    startDate: '2020-05-23',
    endDate: null,
  },
]

function Projects() {
  const navigate = useNavigate()
  const {
    isLoading,
    data: projectsData,
    isError,
    error,
    isFetching,
    refetch,
  } = useData(gridListTypes.projects)
  const { isShowing, toggle } = useModal()
  const [modalData, setModalData] = useState({})

  if (isFetching) {
    return <ProgressLoading fetched={isFetching} />
  }
  if (isError) {
    return <ErrorFetching error={error} />
  }

  // console.log(projectsData)

  return (
    <Grid container bgcolor="bg.main" sx={{}}>
      <Grid item xs={12}>
        <Stack direction="row" p={1.5} justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Projects</Typography>
          <AQHButton onClick={() => navigate(Constants.ROUTE_PROJECTS_ADD)}>Create</AQHButton>
        </Stack>
      </Grid>
      <Grid item xs={12} px={3}>
        <ListView
          id={gridListTypes.projects}
          style={{ height: 550 }}
          columns={[
            { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth: 80 },
            { name: 'projectName', defaultFlex: 1, header: 'Project', minWidth: 120 },
            // { name: 'projectCode', defaultFlex: 0.75, header: 'Code', minWidth: 200 },
            { name: 'client', defaultFlex: 1, header: 'Client', minWidth: 190 },
            { name: 'brand', defaultFlex: 1, header: 'Brand', minWidth: 190 },
            { name: 'projectManager', defaultFlex: 1, header: 'Manager', minWidth: 190 },
            {
              name: 'startDate',
              defaultFlex: 1,
              header: 'Started On',
              type: 'customDate',
              filterEditor: DateFilter,
              filterEditorProps: (props, { index }) => ({
                dateFormat: 'DD/MM/YYYY',
                cancelButton: false,
                highlightWeekends: false,
                placeholder: index === 1 ? '' : '',
              }),
              render: ({ value }) =>
                moment(value).isValid ? moment(value).format('DD/MM/YYYY') : '',
              minWidth: 100,
            },
            {
              name: 'endDate',
              defaultFlex: 1,
              header: 'Ended On',
              type: 'customDate',
              filterEditor: DateFilter,
              filterEditorProps: (props, { index }) => ({
                dateFormat: 'DD/MM/YYYY',
                cancelButton: false,
                highlightWeekends: false,
                placeholder: index === 1 ? '' : '',
              }),
              render: ({ value }) =>
                moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : 'Active Now',
              minWidth: 100,
            },
            {
              id: 'editProject',
              defaultFlex: 0.8,
              minWidth: 100,
              textAlign: 'start',
              sortable: false,
              render: ({ data }) => (
                <>
                  <IconButton
                    onClick={() => navigate(`${Constants.ROUTE_PROJECTS_EDIT}/${data.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                  {!moment(data.endDate).isValid() ? (
                    <IconButton
                      onClick={() => {
                        setModalData(data)
                        toggle()
                      }}
                    >
                      <EventAvailableIcon />
                    </IconButton>
                  ) : null}
               
                </>
              ),
            },
          ]}
          data={projectsData}
          defaultFilters={[
            { name: 'projectName', operator: 'contains', type: 'string' },
            // { name: 'projectCode', operator: 'contains', type: 'string' },
            { name: 'client', operator: 'contains', type: 'string' },
            { name: 'brand', operator: 'contains', type: 'string' },
            { name: 'projectManager', operator: 'contains', type: 'string' },
            { name: 'startDate', operator: 'afterOrOn', type: 'customDate', value: '' },
            { name: 'endDate', operator: 'afterOrOn', type: 'customDate', value: '' },
          ]}
        />
      </Grid>
      <Modal
        isOpen={isShowing}
        handleClose={toggle}
        title={modalData?.projectName ?? 'End Project'}
        subtitle=""
        closeButtonText="null"
        icon={null}
      >
        <EndProjectModal toggle={toggle} data={modalData} refetch={refetch} />
      </Modal>
    </Grid>
  )
}

export default Projects
