import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const useSwal = () => {
  const MySwal = withReactContent(Swal)
  const triggerModal = ({
    text,
    confirmButtonText = 'Ok',
    icon,
    title,
    footer,
    html,
    showCancelButton = true,
    showConfirmButton= true,
    cancelButtonText='Close',
    target = 'body',
    customClass,
    showCloseButton=true,
  }) =>
    MySwal.fire({
      title,
      text,
      confirmButtonText,
      icon,
      footer,
      showCancelButton,
      showConfirmButton,
      cancelButtonText,
      showCloseButton,
      html,
      target,
      customClass,
    })
  const closeModal = () => MySwal.close()
  return { triggerModal, closeModal }
}

export default useSwal
