/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid, Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useValidateLoginCredentials } from '../../hooks/data/useLogin'
import { setUser, removeUser } from '../../store/authUserSlice'
import { setTokenData } from '../../store/tokensSlice'
import AQHTextField from '../../components/ui/AQHInput'
import Utils from '../../utilities/Utils'
import AQHButton from '../../components/ui/AQHButton'
import Constants from '../../utilities/Constants'

function SignIn() {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const { mutateAsync, isLoading } = useValidateLoginCredentials()
  const dispatch = useDispatch()
  // const notify = (toastMessage) => toast.error(toastMessage)
  const validationSchema = yup.object().shape({
    username: yup.string().required('Enter your username'),
    password: yup
      .string()
      .required('Password is mandatory')
      .min(8, "Password must be minimum 8 char's"),
  })

  const formOptions = {
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: { username: '', password: '' },
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions)


  const onSubmitForm = async (data) => {
    const params = { username: data.username, password: data.password }
    // calling api to validate credentials
    const dataVar = await mutateAsync(params, {
      // eslint-disable-next-line no-shadow
      onSuccess: (res) => {
        if (res === undefined) return
        if (res.status === Constants.STATUS_SUCCESS) {
          // check successful login
          dispatch(setUser(res?.data?.data?.user))
          dispatch(setTokenData(res.data.data?.token))
          // setting lastActive time
          localStorage.setItem('lastActiveTimeStamp', Date.now())
          navigate(from, { replace: true })
        } else {
          // check for errors from response
          Utils.showToast(Utils.returnErrorMsg(res.response), Constants.TYPE_TOAST_DANGER)
        }
      },
      onError: (error) => {
        Utils.showToast(error.getMessage(), Constants.TYPE_TOAST_DANGER)
      },
    })
  }

  return (
    <Box maxHeight="100vh" className="sign-in-root">
      <Grid container direction={{ xs: 'column', md: 'row' }}>
        <Grid
          item
          md={4}
          sx={{
            height: { xs: '50vh', md: '100vh' },
            // overflow: 'hidden',
          }}
          className="center-full welcome-space"
          padding={2}
        >
          <Box bgcolor="#fff" height="100%" width="100%" className="center-full" boxShadow={10} borderRadius={1}>
            <Box
              component="img"
              alt="logo"
              src="B2PLogoTransparent.png"
              className="sign-in-logo"
              sx={{ height: { xs: '90%', md: 'unset' }, width: { xs: 'unset', md: '90%' } }}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          md={8}
          sx={{ height: { xs: '50vh', md: '100vh' } }}
          className="center-full"
          // boxShadow={24}
          // bgcolor="#fca25f"
        >
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            style={{ width: '100%' }}
            className="sign-in-form"
            id="enter-enabled-form"
          >
            <Grid
              container
              direction="column"
              sx={{ marginTop: { md: '20.15%' } }}
              className="center-full"
            >
              <Grid width={{ xs: '90%', sm: '75%', md: '50%' }} md={12} item m={{ xs: 1, md: 2 }}>
                <Typography
                  variant="h4"
                  component="div"
                  color="#000"
                  sx={{ marginBottom: '16px', userSelect: 'none' }}
                  align="center"
                  fontFamily="cursive"
                >
                  Sign In to B2P LIVE
                </Typography>
                <AQHTextField
                  name="username"
                  control={control}
                  label="Username"
                  margin="normal"
                  autoComplete="username"
                  errors={errors}
                />
              </Grid>
              <Grid item width={{ xs: '90%', sm: '75%', md: '50%' }}>
                <AQHTextField
                  name="password"
                  control={control}
                  label="Password"
                  type="password"
                  errors={errors}
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item m={{ xs: 2, md: 4 }}>
                <AQHButton
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ fontSize: '16px !important' }}
                  loading={isLoading}
                >
                  Sign In
                </AQHButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  )
}

export default SignIn
