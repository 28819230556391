/* eslint-disable no-unused-vars */
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'

export const RadioGroupStyled = styled(RadioGroup)(({ theme }) => ({}))

export const RadioStyled = styled(Radio)(({ theme }) => ({}))

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({}))

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({}))

