import PropTypes from 'prop-types'
import React,{ useState, useEffect } from 'react'
import AQHTab from '../components/ui/AQHTab'

function useTabs(props) {
    const {disabledTabs,...rest}  = props
  const [disabledTabsState, setDisabledTabsState] = useState(disabledTabs)
    useEffect(()=>{
        if(disabledTabs){
            setDisabledTabsState(disabledTabs)
        }
    },[])
    function enableTab(tabNames){
        const tmp = new Set([...disabledTabsState])
    tabNames.forEach((element) => {
            tmp.delete(element)
    })
        setDisabledTabsState(Array.from(tmp))
    }
    function disableTab(tabNames){
        setDisabledTabsState((e)=>[...e,...tabNames])
    }
  return {
    enableTab,
    disableTab,
    TabElement: ()=><AQHTab {...rest} disabledTabs={disabledTabsState} />,
  }
}

useTabs.PropTypes = {
  tabs: PropTypes.array.isRequired,
  tabContents: PropTypes.array.isRequired,
  disabledTabs: PropTypes.array,
}

export default useTabs
