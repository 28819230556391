// eslint-disable-next-line import/prefer-default-export
export const settings = {
  organization: 'SysCon',
  title: 'Dhanush',
  themeName: 'default',
  navTitle: 'Dhanush', // Title on the navigation bar
  copyright: 'SysCon Engineering', // Footer details
  copyrightYear: '2022',
  copyrightURL: '', // Leave blank for no url
  version: '1.0', // Version information
  // apiUrl: 'http://avagarwal-001-site1.btempurl.com/api/v1',      // live
  // apiUrl: 'http://ispproject-001-site1.gtempurl.com/api/v1', // live
  // apiUrl: 'http://devapi.dhanushteam.com/api/v1', // live
  apiUrl: process.env.REACT_APP_API_URL, // live
  apiVer: '1.0',
  apiHeader: {
    'Content-Type': 'application/json',
  },
  authToken: 'baf8d7b14bb3c20b00b43358510375a2b23c4eeb9e73c5fba31072e3f2a296fc', // Used for testing
  signInImage: '/letter_a.png',
  defaultStaleTime:5000,
}
