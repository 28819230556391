import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import localStorage from 'redux-persist/es/storage'
import authUserReducer from './authUserSlice'
import settingsReducer from './settingsSlice'
import tokensReducer from './tokensSlice'
import listviewReducer from './listViewSlice'
import navigationReducer from './navigationSlice'
import projectReducer from './projectSlice'

const middlewares = []

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { createLogger } = require(`redux-logger`)
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  })

  middlewares.push(logger)
}

const persistConfig = {
  key: 'root-btponline',
  storage: localStorage,
  whitelist: ['authUser', 'tokens','listView','projectDetails'],
}

const rootReducer = combineReducers({
  authUser: authUserReducer,
  settings: settingsReducer,
  tokens: tokensReducer,
  listView: listviewReducer,
  navigationData: navigationReducer,
  projectDetails: projectReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
})

export const persistor = persistStore(store)
