import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

function controlWrapper(Component) {
  function wrapper(props) {
    const { name, control, defaultValue } = props
    const newprops = { ...props }
    delete newprops.defaultValue
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{}}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Component
            {...newprops}
            onChange={onChange}
            error={error}
            value={value}
            onBlur={onBlur}
          />
        )}
      />
    )
  }
  wrapper.prototype = {
    name: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    defaultValue: PropTypes.any,
  }
  wrapper.defaultProps = {
    defaultValue: [],
  }
  return wrapper
}

export default controlWrapper
