import React from 'react'
import { Button, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import WarningIcon from '@mui/icons-material/Warning'
import { green } from '@mui/material/colors'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'

import {
  DialogStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogContentTextStyled,
  DialogTitleStyled,
} from './ModalStyle'

function ModalAlert({
  alertConfirmFunction,
  closeModal,
  isOpen,
  handleClose,
  subtitle,
  closeButtonText,
  maxWidth,
  ...rest
}) {
  return (
    <DialogStyled
      className="alert-dialog"
      // fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      {...rest}
    >
      <DialogTitleStyled
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          //   paddingLeft: '12px',
          letterSpacing: '0.85px',
          minWidth: { md: '375px' },
          userSelect: 'none',
          bgcolor: 'primary.main',
          color: '#fff',
        }}
        id="max-width-dialog-title"
      >
        {/* <WarningAmberIcon  /> */}
        {/* <ReportGmailerrorredIcon/> */}
        <WarningIcon sx={{ fontSize: '50px'}} className='alert-svg'/>
        {/* <GppMaybeIcon sx={{ fontSize: '50px' }} /> */}
        CONFIRMATION REQUIRED!{' '}
      </DialogTitleStyled>
      <DialogContentStyled>
        <DialogContentTextStyled sx={{ userSelect: 'none', fontSize: '15px' }}>
          {subtitle}
        </DialogContentTextStyled>
        <Stack direction="row" mt={1.25} justifyContent="center">
          <Button
            variant="contained"
            // color="warning"
            onClick={() =>alertConfirmFunction()}
          >
            Confirm
          </Button>
          <Button variant="outlined"  onClick={() => closeModal()}>
            Cancel
          </Button>
        </Stack>
      </DialogContentStyled>
    </DialogStyled>
  )
}

ModalAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  subtitle: PropTypes.any,
  closeButtonText: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
  alertConfirmFunction: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

ModalAlert.defaultProps = {
  subtitle: "Once confirmed, you can't revert the action!",
  closeButtonText: 'Close',
  maxWidth: 'md',
}
export default ModalAlert
