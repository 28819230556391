import { CircularProgress, LinearProgress, Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function ProgressLoading({ fetched }) {
  const [progress, setProgress] = useState(10)
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((v) => {
        if (v < 90) {
          return v + 20
        }

        if (fetched) {
          clearInterval(progressInterval)
          return 100
        }
        if (v === 90) {
          clearInterval(progressInterval)
        }
        return v
      })
    }, 500)
  }, [])

  return (
    <Stack
      height="90vh"
      sx={{ position: 'relative',justifyContent: 'center', alignItems: 'center' }}
      bgcolor="bg.main"
    >
      <CircularProgress color='primary'/>

      <LinearProgress variant="determinate" color="primary" sx={{position:'absolute',top:'0px',width:'100%'}} value={progress} />
    </Stack>
  )
}

ProgressLoading.propTypes = {
  fetched: PropTypes.bool.isRequired,
}

export default ProgressLoading
