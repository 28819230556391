/**
=========================================================
*  - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { createTheme } from '@mui/material/styles'

//  base styles
import colors from './base/colors'
import breakpoints from './base/breakpoints'
import typography from './base/typography'
import boxShadows from './base/boxShadows'
import borders from './base/borders'
import globals from './base/globals'

//  helper functions
import boxShadow from './functions/boxShadow'
import hexToRgb from './functions/hexToRgb'
import linearGradient from './functions/linearGradient'
import pxToRem from './functions/pxToRem'
import rgba from './functions/rgba'

//  components base styles for @mui material components
import container from './components/container'
// import button from "./components/button";
// import menu from "./components/menu";
// import menuItem from "./components/menu/menuItem";
// import divider from "./components/divider";
// import formControlLabel from "./components/form/formControlLabel";
// import formLabel from "./components/form/formLabel";
// import checkbox from "./components/form/checkbox";
// import radio from "./components/form/radio";
// import autocomplete from "./components/form/autocomplete";
// import link from "./components/link";

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    // MuiMenu: { ...menu },
    // MuiMenuItem: { ...menuItem },
    // MuiDivider: { ...divider },
    // MuiFormControlLabel: { ...formControlLabel },
    // MuiFormLabel: { ...formLabel },
    // MuiCheckbox: { ...checkbox },
    // MuiRadio: { ...radio },
    // MuiAutocomplete: { ...autocomplete },
    // MuiLink: { ...link },
  },
})
