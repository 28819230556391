import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  gridFilters: [],
  skip: [],
}

export const listViewSlice = createSlice({
  name: 'listView',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const filters = state.gridFilters.filter((e) => e.id !== action.payload.id)
      return { ...state, gridFilters: [...filters, action.payload] }
    },
    destoryFilter: (state, action) => ({
      ...state,
      gridFilters: state.gridFilters.filter((e) => e.id !== action.payload),
    }),
    setPage: (state, action) => {
      const skips = state.skip.filter((e) => e.id !== action.payload.id)
      return {
        ...state,
        skip: [...skips, action.payload],
      }
    },
  },
})

export const listView = (state) => state.listView

export const { setFilter, destoryFilter, setPage } = listViewSlice.actions

export default listViewSlice.reducer
