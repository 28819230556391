// eslint-disable-next-line import/no-unresolved
import apiURLs from '../../utilities/apiURLs'
import { request } from '../../utilities/axiosUtils'

// project

export const listProjects = (requestData) =>
  request({
    url: apiURLs.listProjects,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const getProjectByID = async (requestData) =>
  request({
    url: `${apiURLs.getProjectByID}/${requestData.projectID}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const addProject = (requestData) =>
  request({
    url: apiURLs.createProject,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const editProject = (requestData) =>
  request({
    url: apiURLs.editProject,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const closeProject = (requestData) =>
  request({
    url: `${apiURLs.closeProject}${requestData.projectId}`,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })



// backend team/ clientUsers

export const listBackteam = (requestData) =>
  request({
    url: apiURLs.listBackteam,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const getBackteamById = async (requestData) =>
  request({
    url: `${apiURLs.getBackteamById}/${requestData.userId}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const addBackteam = (requestData) =>
  request({
    url: apiURLs.createBackteam,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const editBackteam = (requestData) =>
  request({
    url: apiURLs.editBackteam,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const deleteBackteam = (requestData) =>
  request({
    url: apiURLs.deleteBackteam,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const editBackteamPassword = (requestData) =>
  request({
    url: apiURLs.editBackteamPassword,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

//  field team
export const listFieldTeam = async (requestData) =>
  request({
    url: apiURLs.listFieldTeam,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const getFieldTeamById = async (requestData) =>
  request({
    url: `${apiURLs.getFieldTeamById}/${requestData.fieldTeamId}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const addFieldTeam = (requestData) =>
  request({
    url: apiURLs.createFieldTeam,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const editFieldTeam = (requestData) =>
  request({
    url: apiURLs.editFieldTeam,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const deleteFieldTeam = (requestData) =>
  request({
    url: apiURLs.deleteBackteam,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const editFieldTeamPassword = (requestData) =>
  request({
    url: apiURLs.editFieldTeamPassword,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

// form(tab on edit project mode)

export const getFormDefinitions = async (requestData) =>
  request({
    url: `${apiURLs.getFormDefinitions}${requestData.projectId}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

  export const addFormDefinitions = (requestData) =>
  request({
    url: apiURLs.addFormDefinitions,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

  export const editFormDefinitions = (requestData) =>
  request({
    url: apiURLs.editFormDefinitions,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

// Data(formData)
export const getFormDataById = async (requestData) =>
  request({
    url: `${apiURLs.getFormDataById}/${requestData.formDataId}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const exportFormDataExcel = async (requestData) =>
  request({
    url: `${apiURLs.exportExcel}?projectId=${requestData.projectId}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const exportFormDataPDF = async (requestData) =>
  request({
    // url: `${apiURLs.exportPDF}?projectId=${requestData.projectId}${requestData.withImage===0?'&withimage='.concat(requestData.withImage):''}&startdate=${requestData.startDate}&enddate=${requestData.endDate}`,
    url: `${apiURLs.exportPDF}?projectId=${requestData.projectId}&withimage=${requestData.withImage}&startdate=${requestData.startDate}&enddate=${requestData.endDate}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

// store

export const listStores = (requestData) =>
  request({
    url: apiURLs.listStore,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const getStoreById = async (requestData) =>
  request({
    url: `${apiURLs.getStoreById}/${requestData.storeId}`,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const addStore = (requestData) =>
  request({
    url: apiURLs.createStore,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const editStore = (requestData) =>
  request({
    url: apiURLs.editStore,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const deleteStore = (requestData) =>
  request({
    url: apiURLs.deleteStore,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

// image

export const createDocument = (requestData) =>
  request({
    url: apiURLs.createDocument,
    method: apiURLs.postApiMethod,
    data: requestData,
    requiredToken: true,
  })

// list data (combo box options)

export const listOrganizations = (requestData) =>
  request({
    url: apiURLs.listOrganizations,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

// prev...................

export const listAgencies = (requestData) =>
  request({
    url: apiURLs.listAgency,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listAgencyManagement = (requestData) =>
  request({
    url: apiURLs.listAgencyManagement,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listRoles = (requestData) =>
  request({
    url: apiURLs.listRole,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listPromoterTypes = (requestData) =>
  request({
    url: apiURLs.listPromoterTypes,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listStoreChannels = () =>
  request({
    url: apiURLs.listStoreChannel,
    method: apiURLs.getApiMethod,
    requiredToken: true,
  })

export const listStoreSubChannels = (requestData) =>
  request({
    url: apiURLs.listStoreSubChannel,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listStoreVolume = (requestData) =>
  request({
    url: apiURLs.listStoreVolume,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listAccountStores = (requestData) =>
  request({
    url: apiURLs.listStoreAccounts,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listISPTypes = (requestData) =>
  request({
    url: apiURLs.listISPTypes,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listCities = (requestData) =>
  request({
    url: apiURLs.listCity,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listStates = (requestData) =>
  request({
    url: apiURLs.listState,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listZones = (requestData) =>
  request({
    url: apiURLs.listZone,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listProductBrands = (requestData) =>
  request({
    url: apiURLs.listProductBrand,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listProductCategories = (requestData) =>
  request({
    url: apiURLs.listProductCategories,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listProductSubCategories = (requestData) =>
  request({
    url: apiURLs.listProductSubCategories,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listProductVariants = (requestData) =>
  request({
    url: apiURLs.listProductVariants,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listIspTypes = (requestData) =>
  request({
    url: apiURLs.listIspTypes,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listAttendanceType = (requestData) =>
  request({
    url: apiURLs.listAttendanceType,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listAttendanceSubType = (requestData) =>
  request({
    url: apiURLs.listAttendanceSubType,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listBranches = (requestData) =>
  request({
    url: apiURLs.listBranches,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listMerchandisingType = (requestData) =>
  request({
    url: apiURLs.listMerchandisingType,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listVisibility = (requestData) =>
  request({
    url: apiURLs.listVisibility,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })

export const listCompetitionBrand = (requestData) =>
  request({
    url: apiURLs.listCompetitionBrand,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
export const listCompetitionCategory = (requestData) =>
  request({
    url: apiURLs.listCompetitionCategory,
    method: apiURLs.getApiMethod,
    data: requestData,
    requiredToken: true,
  })
