import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-unresolved
import Constants from 'utilities/Constants'

// eslint-disable-next-line react/prop-types
function RequireAuth({ allowedRoles }) {
  const authUser = useSelector((store) => store.authUser)
  const location = useLocation()

  if (!authUser || authUser?.userId === 0)
    return <Navigate to={Constants.ROUTE_SIGNIN_URL} state={{ from: location }} replace />
  
  // eslint-disable-next-line react/prop-types
  if (authUser?.userId && !allowedRoles?.includes(authUser?.role))
    return <Navigate to={Constants.ROUTE_UNAUTHORIZED_URL} state={{ from: location }} replace />
  return <Outlet />
}

export default RequireAuth
