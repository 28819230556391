import React, { useState } from 'react'
import { Typography, Menu, MenuItem, Box, Stack, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import Constants from 'utilities/Constants'

import { AppBarStyled, ToolBarStyled, IconsContainer } from './NavbarStyle'
import { removeUser, authUser } from '../../store/authUserSlice'
import { removeTokenData } from '../../store/tokensSlice'
import { menuItems } from '../../configs/menuConfigs'

// eslint-disable-next-line import/prefer-default-export, react/prop-types
export function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState(null)

  // Checking if user is logged in
  const loggedInUser = useSelector(authUser)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(removeUser())
    dispatch(removeTokenData())
    localStorage.clear()
    navigate(Constants.ROUTE_SIGNIN_URL, { replace: true })
  }

  const openMenu = Boolean(anchorEl)

  return (
    <AppBarStyled
      position="fixed"
      elevation={0}
      sx={{ height: '114px', backgroundColor: '#dce3e6' }}
    >
      <ToolBarStyled>
        <Box component="img" my={0.5} height="75px" src="/BTPLogo.png" />
        <IconsContainer>
          <Typography variant="body1" sx={{ userSelect: 'none' }}>
            {`${loggedInUser.firstName} ${loggedInUser.lastName}`}
          </Typography>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon />
          </IconButton>
        </IconsContainer>
      </ToolBarStyled>

      <Stack
        direction="row"
        sx={{ height: '100%', alignItems: 'center', px: 2 }}
        spacing={2}
        bgcolor="#fc6f05"
        color="#fff"
      >
        {menuItems.map((menuItem) => {
          const hasAccess =
            menuItem.auth.length > 0 ? menuItem.auth.includes(loggedInUser.role) : true
          return hasAccess ? (
            <Typography
              key={menuItem.id}
              color={window.location.pathname.includes(menuItem.link) ? '#000' : null}
              className="menu-item"
              onClick={() => navigate(menuItem.link)}
              fontSize={18}
            >
              {menuItem.name}
            </Typography>
          ) : null
        })}
      </Stack>

      <Menu
        id="top-menu"
        aria-labelledby="top-menu-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </AppBarStyled>
  )
}
