import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  organinzation: '',
  title: '',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.organization = action.payload.organinzation
    },
    setTitle: (state, action) => {
      const title =
        action.payload.title && action.payload.title !== ''
          ? `${state.organinzation}: ${action.payload.title}`
          : state.organinzation
      // eslint-disable-next-line no-param-reassign
      state.title = title
    },
  },
})

export const title = (state) => state.settings.title

export const organization = (state) => state.settings.organinzation

export const { setOrganization, setTitle } = settingsSlice.actions

export default settingsSlice.reducer
