import { Toolbar, styled } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'

// eslint-disable-next-line import/no-unresolved
import { configs } from 'assets/theme/base/globals'

export const AppBarStyled = styled(
  MuiAppBar,
  {},
)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

export const ToolBarStyled = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  background: '#ffffff',
})

export const IconsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
})
