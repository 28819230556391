import { Box, Stack, Grid, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import AQHDropdown from '../../components/ui/AQHDropdown'
import AQHPickerDate from '../../components/ui/AQHPicker/date'
import AQHButton from '../../components/ui/AQHButton'
import AQHTextField from '../../components/ui/AQHInput'
import { useCloseProject } from '../../hooks/data/useProjectData'

export default function EndProjectModal({ toggle, data, refetch }) {
  const validationSchema = yup.object().shape({
    endDate: yup
      .date()
      .required('End Date is required')
      .typeError('End Date is required')
      .max(moment(), 'End Date can not be in future').min(moment(data.startDate),'End Date can not be before start date'),
    email1: yup
      .string()
      .email('Email must be a valid email')
      .required('At least One email id is required'),
    email2: yup.string().email('Email must be a valid email'),
    email3: yup.string().email('Email must be a valid email'),
    email4: yup.string().email('Email must be a valid email'),
    email5: yup.string().email('Email must be a valid email'),
  })

  const formOptions = {
    mode: 'onBlur',
    defaultValues: {
      email1: '',
      email2: '',
      email3: '',
      email4: '',
      email5: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  }
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    reset,
  } = useForm(formOptions)

  const { mutateAsync: closeProject } = useCloseProject()
  const [dataLoading, setDataLoading] = useState(false)
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)

  useEffect(() => {
    const pData = {
      projectName: data.projectName,
      projectBrand: data.brand,
      projectClient: data.client,
      projectManager: data.projectManager,
      startDate: data.startDate,
    }
    reset({ ...pData })
  }, [])

  const onSubmitForm = async (formData) => {
    // console.log(formData)
    const emails = [formData.email1]
    const tmp = [formData.email2, formData.email3, formData.email4, formData.email5]
    tmp.forEach((e) => {
      if (e) emails.push(e)
    })
    setDataLoading(true)
    const params = {
      projectId: data.id,
      endDate: moment(formData.endDate).format('YYYY-MM-DD'),
      emails,
    }
    await closeProject(params, {
      // eslint-disable-next-line no-shadow
      onSuccess: (response) => {
        // eslint-disable-next-line no-prototype-builtins
        // console.log(response)
        if (!response) {
          notify('Something went wrong !')
          return
        }
        setDataLoading(false)
        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty('error')) {
          notify(response.data.error.message)
        } else {
          notifySuccess(response.data.message)
          toggle()
          refetch()
        }
      },
      onError: (error) => {
        notify(error.getMessage())
      },
    })
  }

  return (
    <Grid
      container
      spacing={1.5}
      component="form"
      onSubmit={handleSubmit(onSubmitForm)}
      width={{ xs: '250px', sm: '500px', md: '700px' }}
    >
      {/* <Grid item xs={12}>
        <AQHTextField
          name="projectName"
          control={control}
          label="Project Name"
          margin="normal"
          maxLength={100}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid> */}
      <Grid item xs={6}>
        <AQHTextField
          name="projectBrand"
          control={control}
          label="Project Brand"
          margin="normal"
          maxLength={100}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <AQHTextField
          name="projectClient"
          control={control}
          label="Project Client"
          margin="normal"
          maxLength={100}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <AQHTextField
          name="projectManager"
          control={control}
          label="Project Manager"
          margin="normal"
          maxLength={100}
          errors={errors}
          inputProps={{ readOnly: true }}
        />
      </Grid>

      <Grid item xs={6} name="startDate">
        <AQHPickerDate
          fullWidth
          type="date"
          label="Start Date"
          name="startDate"
          control={control}
          inputFormat="dd/MM/yyyy"
          readOnly
        />
      </Grid>

      <Grid item xs={6} name="endDate">
        <AQHPickerDate
          fullWidth
          type="date"
          label="End Date"
          name="endDate"
          control={control}
          inputFormat="dd/MM/yyyy"
          minDate={moment(data.startDate)}
          maxDate={moment()}
        />
      </Grid>

      <Grid item xs={6}>
        <AQHTextField
          name="email1"
          control={control}
          label="Email 1"
          margin="normal"
          maxLength={100}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <AQHTextField
          name="email2"
          control={control}
          label="Email 2"
          margin="normal"
          maxLength={100}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <AQHTextField
          name="email3"
          control={control}
          label="Email 3"
          margin="normal"
          maxLength={100}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <AQHTextField
          name="email4"
          control={control}
          label="Email 4"
          margin="normal"
          maxLength={100}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <AQHTextField
          name="email5"
          control={control}
          label="Email 5"
          margin="normal"
          maxLength={100}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12} mx={{ xs: 0.5, sm: 1, md: 3 }} my={1} className="action-buttons">
        <AQHButton
          variant="contained"
          size="medium"
          withLoader
          loading={dataLoading}
          // disabled={dataLoading || (id ? loadedBit < 3 : loadedBit < 1)}
          type="submit"
        >
          Save
        </AQHButton>

        <Button
          variant="outlined"
          size="medium"
          onClick={toggle}
          sx={{ margin: '20px', fontWeight: '500', fontSize: '12px' }}
          // disabled={dataLoading}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  )
}

EndProjectModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
}
