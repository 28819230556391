import React from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import {
  DialogStyled,
  DialogContentStyled,
  DialogContentTextStyled,
} from './ModalStyle'

function ImageModal({ isOpen, handleClose, children, maxWidth }) {
  return (
    <DialogStyled
      // fullWidth
      
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      className='modal-show'
    >
      <DialogContentStyled sx={{padding:0}}>
        {children}
      </DialogContentStyled>
    </DialogStyled>
  )
}

ImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  maxWidth: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
}

ImageModal.defaultProps = {
  maxWidth: 'md',
}
export default ImageModal
