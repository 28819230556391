import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Box, Stack, Button } from '@mui/material'
import { NavbarNoAuth } from '../../layouts/navbar/NavbarNoAuth'
import Constants from '../../utilities/Constants'

function GuardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      version="1"
      viewBox="0 0 500 500"
    >
      <path
        d="M1637 4302l-848-377 4-695c4-749 4-757 62-995 194-811 816-1493 1553-1705 83-24 83-24 155-6 173 43 426 163 608 287 477 327 836 857 972 1434 59 249 60 265 64 996 4 555 2 677-9 685-20 16-1686 754-1701 754-6 0-394-170-860-378zm863-872v-830h1330l-6-32c-73-429-221-767-471-1075-202-250-496-465-768-563-27-10-58-21-67-25-17-7-18 39-20 841l-3 849-662 3-663 2v1078l653 290c358 160 658 291 665 291 9 1 12-171 12-829z"
        transform="matrix(.1 0 0 -.1 0 500)"
      />
    </svg>
  )
}

const HomeIcon = (
  <svg
    height="30"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
    />
  </svg>
)

function Unauthorized() {
  const navigate = useNavigate()

  return (
    <Box sx={{ position: 'relative', top: '64px' }}>
      <NavbarNoAuth />

      <Stack className="center-full" sx={{ height: 'calc(100vh - 64px)' }}>
        <GuardIcon />
        <Typography variant="h6" px={5} color="error.main">
          You are not authorized to do the current action / view the data. Click here to go to the
          home page and try again, or contact the administrator !
        </Typography>

        <Button
          variant="outlined"
          size="large"
          startIcon={HomeIcon}
          onClick={() => navigate(Constants.ROUTE_INDEX_URL, { replace: true })}
        >
          Dashboard
        </Button>
      </Stack>
    </Box>
  )
}

export default Unauthorized
