import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessToken: '',
  refreshToken: '',
  expiresIn: 0,
}

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokenData: (state, action) => {
      const tokenData = action.payload
      return {
        accessToken: tokenData.accessToken,
        refreshToken: tokenData.refreshToken,
        expiresIn: Number(tokenData.expiresIn),
      }
    },
    // eslint-disable-next-line no-unused-vars
    removeTokenData: (state, action) => initialState,
  },
})

export const tokens = (state) => state.tokens

export const { setTokenData, removeTokenData } = tokensSlice.actions

export default tokensSlice.reducer
