import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  competition: [],
  merchandising: [],
  offers: [],
}

export const navigationSlice = createSlice({
  name: 'navigationData',
  initialState,
  reducers: {
    setNavigationData: (state, action) => {
      const nData = action.payload
      return {
        competition: nData.competition || state.competition,
        merchandising: nData.merchandising || state.merchandising,
        offers: nData.offers || state.offers,
      }
    },
    removeNavigationData: () => initialState,
  },
})

export const navigationData = (state) => state.navigationData

export const { setNavigationData, removeNavigationData } = navigationSlice.actions

export default navigationSlice.reducer
