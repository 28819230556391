module.exports = Object.freeze({
  ROUTE_SIGNIN_URL: '/signin',
  ROUTE_UNAUTHORIZED_URL: '/unauthorized',
  ROUTE_FALLBACK_URL: '/404',
  ROUTE_INDEX_URL: '/',
  ROUTE_MISC_INDEX: '*',
  ROUTE_PROJECTS: '/app/projects',
  ROUTE_PROJECTS_ADD: '/app/projects/add',
  ROUTE_PROJECTS_EDIT: '/app/projects/edit',
  ROUTE_PROJECTS_FIELDTEAM_ADD: '/app/projects/fieldteam-add',
  ROUTE_PROJECTS_FIELDTEAM_EDIT: '/app/projects/fieldteam-edit',
  ROUTE_DATA: '/app/data',
  ROUTE_USER_LIST: '/app/users',
  ROUTE_STORE_LIST: '/app/stores',
  postApiMethod: 'post',
  getApiMethod: 'get',
  POSITION_TOAST_TOP_RIGHT: 'top-right',
  TYPE_TOAST_SUCCESS: 'success',
  TYPE_TOAST_DANGER: 'error',
  TYPE_TOAST_WARNING: 'warning',
  STATUS_SUCCESS: 200,
  STATUS_UNAUTHORIZED: 401,
  STATUS_NOT_FOUND: 404,
  STATUS_BAD_REQUEST: 400,
  STATUS_UNPROCESSABLE_ENTITY: 422,
  STATUS_CONFLICT: 409,
  STATUS_ERROR_NETWORK: 0,
  STATUS_INTERNAL_ERROR: 500,
  ERROR_SOMETHING_WRONG: 'Something went wrong!',
  ERROR_NETWORK_MSG: 'Check internet connectivity !',
  ERROR_SESSION_LOGOUT_MSG: 'You have been inactive since long time.<br/>Login again !',
  ERROR_IMAGE_NOT_UPLOADED_MSG: 'Profile Image  is mandatory !',
  MSG_BAD_REQUEST: 'Bad request !',
  MSG_UNAUTHORIZED:
    'You are not authorized to access the data, or your credentials have expired. Login and try again, or contact the Administrator.',
  MSG_INTERNAL_ERROR: 'Oops! Something went wrong. Please try again!',
  MSG_NOT_FOUND: 'Oops! Not found. Please try again!',
  FILE_URL: process.env.REACT_APP_FILE_URL,
  USER_LIST_URL: '/app/users',
  USER_SESSION_TIMEOUT: 3000000, // 50 mins for testing, live make 30 minutes
  // USER_SESSION_TIMEOUT: 3000, // 3 secs for testing
  FILE_DOWNLOADING: 'Download starting...',
  FILE_DOWNLOAD_STARTED: 'Download started.',
})
