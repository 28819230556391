/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import controlWrapper from '../../controlWrapper'
import { InputStyled } from './InputStyled'

// control and default values should be sent to <Controller> i.e. to the control wrapper
// not making any changes as we don not require the functionality at present

export function Input(props) {
  const {
    name,
    label,
    control,
    defaultValue,
    fullWidth,
    size,
    margin,
    variant,
    children,
    required,
    maxLength,
    handleOnChange,
    inputProps,
    onChange,
    error,
    value,
    type,
  
    ...rest
  } = props

  if (value === undefined || value === null)
  // eslint-disable-next-line no-console
    console.log('AQHInput value is null', name, label)


  return (
    <InputStyled
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      size={size}
      required={required}
      id={name}
      label={label}
      name={name}
      value={value}
      inputProps={inputProps ? {maxLength: maxLength || 100, ...inputProps} : {maxLength: maxLength || 100}}
      onChange={
        handleOnChange
          ? (event) => {
            onChange(type==='number'?event.target.value.substring(0, +maxLength):event.target.value)
            handleOnChange()
          }
          : (event)=>onChange(type==='number'?event.target.value.substring(0, +maxLength):event.target.value)
          
      }
      type={type}
      error={!!error}
      helperText={error ? error.message : null}
      {...rest}
    />
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  margin: PropTypes.oneOf(['none', 'normal', 'dense']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  handleOnChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  size: PropTypes.oneOf(['medium', 'small', PropTypes.string]),
  required: PropTypes.bool,
  type: PropTypes.string,
  // type: PropTypes.oneOf(['text', 'number', 'password', 'file']),
  maxLength:PropTypes.number
}
Input.defaultProps = {
  fullWidth: true,
  size: 'small',
  required: false,
  margin: 'dense',
  variant: 'outlined',
  error: '',
  handleOnChange: false,
  type: 'text',
  maxLength:Infinity
}

const AQHTextField = controlWrapper(Input)

export default AQHTextField