import ReactDataGrid from '@inovua/reactdatagrid-community'
import moment from 'moment'

import { filterLimits } from '../configs/gridListSettings'

window.moment = moment

// custom dateType for ignoring certain date filtering errors

const customFilterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  customDate: {
    name: 'customDate',
    emptyValue: '',
    operators: [
      {
        name: 'after',
        emptyValue: '',
        fn: function fn(_ref25) {
          const { value, filterValue } = _ref25
          const dateFormat = _ref25.column?.dateFormat || 'DD/MM/YYYY'

          return filterValue
            ? window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isAfter(window.moment(filterValue, dateFormat))
            : true
        },
      },
      {
        name: 'afterOrOn',
        fn: function fn(_ref26) {
          const { value, filterValue } = _ref26
          const dateFormat = _ref26.column?.dateFormat || 'DD/MM/YYYY'
          return filterValue != null
            ? window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isSameOrAfter(window.moment(filterValue, dateFormat))
            : true
        },
      },
      {
        name: 'before',
        fn: function fn(_ref27) {
          const { value, filterValue } = _ref27
          const dateFormat = _ref27.column?.dateFormat || 'DD/MM/YYYY'
          return filterValue != null
            ? window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isBefore(window.moment(filterValue, dateFormat))
            : true
        },
      },
      {
        name: 'beforeOrOn',
        fn: function fn(_ref28) {
          const { value, filterValue } = _ref28
          const dateFormat = _ref28.column?.dateFormat || 'DD/MM/YYYY'
          return filterValue != null
            ? window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isSameOrBefore(window.moment(filterValue, dateFormat))
            : true
        },
      },
      {
        name: 'eq',
        fn: function fn(_ref29) {
          const { value, filterValue } = _ref29
          const dateFormat = _ref29.column?.dateFormat || 'DD/MM/YYYY'

          return filterValue
            ? window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isSame(window.moment(filterValue, dateFormat))
            : true
        },
      },
      {
        name: 'neq',
        fn: function fn(_ref30) {
          const { value, filterValue } = _ref30
          const dateFormat = _ref30.column?.dateFormat || 'DD/MM/YYYY'
          return filterValue
            ? !window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isSame(window.moment(filterValue, dateFormat))
            : true
        },
      },
      {
        name: 'inrange',
        fn: function fn(_ref31) {
          const { value, filterValue } = _ref31
          const dateFormat = _ref31.column?.dateFormat || 'DD/MM/YYYY'

          const ref32 = filterValue || {}
          const { start, end } = ref32

          if (start && end) {
            return (
              window
                .moment(window.moment(value).format(dateFormat), dateFormat)
                .isSameOrAfter(window.moment(start, dateFormat)) &&
              window
                .moment(window.moment(value).format(dateFormat), dateFormat)
                .isSameOrBefore(window.moment(end, dateFormat))
            )
          }

          if (start) {
            return window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isSameOrAfter(window.moment(start, dateFormat))
          }

          if (end) {
            return window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isSameOrBefore(window.moment(end, dateFormat))
          }

          return true
        },
      },
      {
        name: 'notinrange',
        fn: function fn(_ref33) {
          const { value, filterValue } = _ref33
          const dateFormat = _ref33.column?.dateFormat || 'DD/MM/YYYY'

          const ref34 = filterValue || {}
          const { start, end } = ref34

          if (start && end) {
            return (
              window
                .moment(window.moment(value).format(dateFormat), dateFormat)
                .isBefore(window.moment(start, dateFormat)) ||
              window
                .moment(window.moment(value).format(dateFormat), dateFormat)
                .isAfter(window.moment(end, dateFormat))
            )
          }

          if (start) {
            return window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isBefore(window.moment(start, dateFormat))
          }

          if (end) {
            return window
              .moment(window.moment(value).format(dateFormat), dateFormat)
              .isAfter(window.moment(end, dateFormat))
          }

          return true
        },
      },
    ],
  },
}

// limiting the filterTypes

filterLimits.forEach((item) => {
  customFilterTypes[Object.keys(item)[0]].operators = customFilterTypes[
    Object.keys(item)[0]
  ].operators.filter((operator) => item[Object.keys(item)[0]].includes(operator.name))
})

export default customFilterTypes
