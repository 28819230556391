/* eslint-disable react/self-closing-comp */
import { Stack, Typography } from '@mui/material'
import React from 'react'

// eslint-disable-next-line import/prefer-default-export
export function Footer() {
  return (
    <Stack sx={{ minHeight: '2vh', backgroundColor: '#f0f0f0' }} p={1} marginTop={1}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} color='#c0c0c0'>
        <Typography  sx={{userSelect:'none', fontSize:12}}>BTP LIVE &copy; 2022-2023</Typography>

        <Typography
          component="a"
          href="https://sysconengineering.com"
          target="_blank"
          variant="body1"
          fontSize={12}
          color='#fc6f05'
        >
          Syscon Engineering
        </Typography>
        <Typography fontSize={12}>Version: {process.env.REACT_APP_VERSION}</Typography>
      </Stack>
    </Stack>
  )
}
