import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { TabStyled, TabContextStyled, TabListStyled, TabPanelStyled } from './StyledAQHTab'

function AQHTab(props) {
  const { tabs, tabContents, tabListProps, disabledTabs } = props
  const [value, setValue] = useState('0')
  const [customDisable, setCustomDisable] = useState({})
  const handleChange = (e, v) => {
    setValue(v)
  }
  return (
    <TabContextStyled value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabListStyled
          onChange={handleChange}
          // textColor='secondary'
          // indicatorColor='secondary'
          // centered
          // variant='scrollable'
          // scrollButtons='auto'
          {...tabListProps}
        >
          {tabs.map((item, index) => (
            <TabStyled
              key={item.name}
              label={item.name}
              value={`${index}`}
              disabled={
                item.customDisabled
                  ? item.customDisabled
                  : disabledTabs.indexOf(item.name) !== -1
              }
             
            />
          ))}
        </TabListStyled>
      </Box>
      {tabContents.map((item, index) => (
        <TabPanelStyled key={`${Math.random() * 10}`} value={`${index}`}>
          {item}
        </TabPanelStyled>
      ))}
    </TabContextStyled>
  )
}

AQHTab.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabContents: PropTypes.array.isRequired,
  tabListProps: PropTypes.any,
  disabledTabs: PropTypes.array,
}
AQHTab.defaultProps = {
  tabListProps: null,
  disabledTabs: [],
}

export default AQHTab
