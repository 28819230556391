/* eslint-disable react/prop-types */
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
import controlWrapper from '../../controlWrapper'
import { AutocompleteStyled, CheckboxStyled, TextFieldStyled } from './DropdownStyled'

function Index(props) {
  const {
    textFieldProps,
    autocompleteProps,
    name,
    options,
    loading,
    showCheckbox,
    required,
    multiple,
    matchId,
    label,
    onChange,
    onBlur,
    error,
    value,
    size,
    idField,
    ...rest
  } = props


  if (options === undefined)
    // eslint-disable-next-line prefer-template
    console.log("AQHDropdone - " + name) // eslint-disable-line no-console

  // TO REVIEW AND COMPLETE LATER SO THE OPTION LIST WORKS IF ID HAS A DIFFERENT NAME
  // let isOptionEqualToValueMethod = isOptionEqualToValue  ? autocompleteProps.isOptionEqualToValue  : 
  //   (option, optionValue) => (optionValue ? option.id === (optionValue?.id || optionValue) : false)
  // isOptionEqualToValueMethod = idField ? isOptionEqualToValueMethod :
  //   (option, optionValue) => (optionValue ? option[idField] === (optionValue[idField] || optionValue) : false)

  let currentValue = multiple ? value || [] : value || null
  if (matchId) {
    currentValue = multiple
      ? (value || []).map((i) => options.find((j) => (j.id || j) === i))
      : options.find((i) => (i.id || i) === value) || null
  }
  return (
    <AutocompleteStyled
      {...autocompleteProps}
      value={currentValue}
      size={size}
      loading={loading}
      multiple={multiple}
      options={options}
      disableCloseOnSelect={
        typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
          ? autocompleteProps.disableCloseOnSelect
          : !!multiple
      }
      isOptionEqualToValue={
        autocompleteProps?.isOptionEqualToValue
          ? autocompleteProps.isOptionEqualToValue
          : 
          (option, optionValue) => (optionValue ? option.id === (optionValue?.id || optionValue) : false)
      }
      getOptionLabel={
        autocompleteProps?.getOptionLabel
          ? autocompleteProps.getOptionLabel
          : (option) => `${option?.label || option}`
      }
      
      onChange={(event, newValue, reason, details) => {
        let changedVal = newValue
        if (matchId) {
          changedVal = Array.isArray(newValue) ? newValue.map((i) => i?.id || i) : newValue?.id || newValue
        }
        onChange(changedVal)
        if (autocompleteProps?.onChange) {
          autocompleteProps.onChange(event, newValue, reason, details)
        }
      }}
      renderOption={
        showCheckbox
          ? // eslint-disable-next-line no-shadow
          (props, option, { selected }) => (
            <li {...props}>
              <CheckboxStyled sx={{ marginRight: 1 }} checked={selected} />
              {autocompleteProps?.getOptionLabel?.(option) || option.label || option}
            </li>
          )
          : undefined
      }
      onBlur={(event) => {
        onBlur()
        if (typeof autocompleteProps?.onBlur === 'function') {
          autocompleteProps.onBlur(event)
        }
      }}
      renderInput={(params) => (
        <TextFieldStyled
          name={name}
          label={label}
          {...textFieldProps}
          {...params}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            ...textFieldProps?.InputProps,
          }}
          helperText={error ? error.message : textFieldProps?.helperText}          
        />
      )}
      
      {...rest}
    />
  )
}
Index.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['medium', 'small', PropTypes.string]),
  value: PropTypes.any,
  showCheckbox: PropTypes.bool,
  loading: PropTypes.bool,
  matchId: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.any,
  autocompleteProps: PropTypes.shape({
    disableCloseOnSelect: PropTypes.bool,
    isOptionEqualToValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  textFieldProps: PropTypes.shape({
    InputProps: PropTypes.any,
    helperText: PropTypes.string,
  }),
}

Index.defaultProps = {
  multiple: false,
  showCheckbox: false,
  size:'small',
  loading: false,
  matchId: false,
  error: '',
  required: false,
  autocompleteProps: {
    disableCloseOnSelect: false,
    isOptionEqualToValue: (option, optionValue) => (optionValue ? option.id === (optionValue?.id || optionValue) : false),
    getOptionLabel: (option) => `${option?.label || option}`,
    onChange: () => {},
    onBlur: () => {},
  },
  textFieldProps: {
    helperText: '',
    InputProps: '',
  },
  value: '',
}
const AQHDropdown = controlWrapper(Index)
export default AQHDropdown
