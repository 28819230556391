/* eslint-disable no-unused-vars */
import axios from 'axios'
// import { useNavigate } from 'react-router-dom'

import { settings } from '../configs/settingsConfigs'
import Constants from './Constants'
import apiURLs from './apiURLs'
import useSwal from '../hooks/useSwal'
import Utils from './Utils'
import { store } from '../store'
import { removeUser } from '../store/authUserSlice'

const client = axios.create({ baseURL: settings.apiUrl })

const displaySwalError = (errMsg, isMsgBig = false) => {
  const { triggerModal } = useSwal()
  triggerModal({
    title: errMsg,
    icon: 'error',
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: 'Close',
    customClass: isMsgBig
      ? {
          title: 'title-class-small',
        }
      : null,
  })
  return false
}

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const checkUserSession = async (options) => {
  const activeSessionTimeOut = Utils.checkUserSessionTimeOut(Date.now())
  if (activeSessionTimeOut && options.url !== apiURLs.login) {
    // localStorage.clear()
    displaySwalError(Constants.ERROR_SESSION_LOGOUT_MSG)

    // await delay(3000) // delay for 3 seconds
    // window.location.href = decodeURIComponent(Constants.ROUTE_SIGNIN_URL)
  }

  // set lastActiveTimeStamp if not then required token
  if (options.url !== apiURLs.login) {
    localStorage.setItem('lastActiveTimeStamp', Date.now())
  }
}

// eslint-disable-next-line import/prefer-default-export
export const request = ({ ...options }) => {
  checkUserSession(options)
  const state = store.getState()
  client.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  if (options.requiredToken) {
    client.defaults.headers.common.Authorization = `Bearer ${state.tokens.accessToken}`
    client.defaults.headers.common['Content-Type'] = 'application/json'
  }
  // eslint-disable-next-line no-param-reassign
  delete options.requiredToken
  // eslint-disable-next-line no-param-reassign
  delete options.token

  const onSuccess = (response) => response

  const onError = (error) => {
    console.log(error)
    switch (error.response.status) {
      case Constants.STATUS_ERROR_NETWORK:
        displaySwalError(Constants.ERROR_NETWORK_MSG)
        break
      case Constants.STATUS_BAD_REQUEST:
        displaySwalError(
          error.response.data
            ? `${error.response.data.split('|').join('<br/>')}`
            : Constants.MSG_BAD_REQUEST,
        )
        break
      case Constants.STATUS_UNAUTHORIZED:
        displaySwalError(
          error.response.data
            ? `${error.response.data.split('|').join('<br/>')}`
            : Constants.MSG_UNAUTHORIZED,
          true,
        )
        store.dispatch(removeUser)
        localStorage.clear()
        if (window.location.pathname !== Constants.ROUTE_SIGNIN_URL) {
          window.location.href = decodeURIComponent(Constants.ROUTE_SIGNIN_URL)
        }
        break
      case Constants.STATUS_NOT_FOUND:
        displaySwalError(Constants.MSG_NOT_FOUND)
        break
      case Constants.STATUS_INTERNAL_ERROR:
        // console.group('STATUS_INTERNAL_ERROR: 500')
        // console.error(error)
        // console.groupEnd()
        displaySwalError(Constants.MSG_INTERNAL_ERROR)
        break
      default:
        // displaySwalError(Constants.ERROR_SOMETHING_WRONG)
        break
    }
  }

  return client(options).then(onSuccess).catch(onError)
}
