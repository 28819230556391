import { useQuery, useMutation } from 'react-query'
import { getFormDefinitions, addFormDefinitions, editFormDefinitions } from './endPoints'

export const useGetFormDefinitionsByID = ({ projectId }) =>
  useQuery(['getFormDefinitions', projectId], () => getFormDefinitions({ projectId }), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

export const useAddFormDefinitions = () => useMutation(addFormDefinitions)
export const useEditFormDefinitions = () => useMutation(editFormDefinitions)

