import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userId: 0,
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
}

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const user = action.payload
      return {
        userId: user.userId,
        userName: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.roleName,
      }
    },
    // eslint-disable-next-line no-unused-vars
    removeUser: (state, action) => initialState,
  },
})

export const authUser = (state) => state.authUser

export const { setUser, removeUser } = authUserSlice.actions

export default authUserSlice.reducer
