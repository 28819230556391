import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TimePickerStyled = styled(TimePicker)((theme) => ({}))

export const DatePickerStyled = styled(DatePicker)((theme) => ({}))

export const DateTimePickerStyled = styled(DateTimePicker)((theme) => ({}))

export const PickerTextFieldStyled = styled(TextField)((theme) => ({}))
