import { useQuery, useMutation } from 'react-query'
import {
  getProjectByID,
  addProject,
  editProject,
  closeProject
} from './endPoints'

export const useGetProjectByID = ({ projectID }) =>
  useQuery(['getProjectByID', projectID], () => getProjectByID({ projectID }), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

export const useAddProject = () => useMutation(addProject)
export const useEditProject = () => useMutation(editProject)
export const useCloseProject = () => useMutation(closeProject)

