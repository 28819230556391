import React from 'react'
import PropTypes from 'prop-types'
import { isDate } from 'lodash'
import moment from 'moment'
import {
  TimePickerStyled,
  DateTimePickerStyled,
  DatePickerStyled,
  PickerTextFieldStyled,
} from './PickerStyled'
import controlWrapperDate from '../../controlWrapper'



function Index(props) {
  const {
    parseError,
    type,
    name,
    required,
    parseDate,
    inputProps,
    onChange,
    size,
    value,
    error,
    inputFormat,
    fullWidth,
    futureDisabled,
    minDate,
    maxDate,
    ...rest
  } = props
 
  const customSX = {
    '& .MuiTypography-root': {
      fontSize: '16px',
      fontWeight: '500',
    },
    '& .MuiPickersDay-dayWithMargin': {
      fontSize: '14px',
    },
  }
  const Element = DatePickerStyled
 
  return (
    <Element
      {...rest}
      value={value || ''}
      inputFormat={inputFormat}
      onChange={(newVal) => onChange(newVal)}
      PopperProps={{ sx: customSX }}
      disableFuture={futureDisabled}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => (
        <PickerTextFieldStyled
          {...params}
          {...inputProps}
          required={!!required}
          error={Boolean(error) || ((value!==null)?!moment(value).isValid():false)}
          size={size}
          fullWidth={fullWidth}
          helperText={
            // eslint-disable-next-line no-nested-ternary
            error
              ? typeof parseError === 'function'
                ? parseError(error)
                : error.message
              : inputProps?.helperText || rest.helperText
          }
        />
      )}
    />
  )
}

Index.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'time', 'datetime']),
  required: PropTypes.bool,
  error: PropTypes.any,
  size: PropTypes.oneOf(['medium', 'small', PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  inputFormat: PropTypes.string,
  fullWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.any,
  inputProps: PropTypes.shape({
    helperText: PropTypes.string,
  }),
  parseError: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  parseDate: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  futureDisabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any
}

Index.defaultProps = {
  required: false,
  error: '',
  size: 'small',
  inputProps: {
    helperText: '',
  },
  parseError: '',
  parseDate: '',
  value: '',
  fullWidth: false,
  futureDisabled: false,
  minDate:null,
  maxDate: null,
  type:'date',
  inputFormat:"dd/MM/yyyy"
}

const AQHPicker = controlWrapperDate(Index)

export default AQHPicker
