import { Stack, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function ErrorFetching({error}){
  return (
    <Stack height="90vh" justifyContent="center" alignContent="center">
      <Typography variant="h5" align="center" color="error.light" component="div">
        Oops! something is wrong.
      </Typography>
      <Typography variant="h5" align="center" color="error.light" component="div">
        Error: {error.message}
      </Typography>
    </Stack>
  )
}
 
ErrorFetching.propTypes = {
    error: PropTypes.object.isRequired,
  }

export default ErrorFetching