import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
// import CssBaseline from '@mui/material/CssBaseline'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Navbar } from '../navbar/Navbar'
import { Footer } from '../footer/Footer'

import { MainArea, ContentArea, FooterArea } from './DefaultLayoutStyle'


export default function DefaultLayout() {
  const theme = useTheme()
  const authUser = useSelector((store) => store.authUser)

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />
      <MainArea >
        <ContentArea>
          <Outlet />
        </ContentArea>
        <FooterArea>
          <Footer />
        </FooterArea>
      </MainArea>
    </Box>
  )
}
