import { Box, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PropTypes from 'prop-types'

function GoogleMap(props) {
  const { mapLinkHandler } = props
  // for parent box, a slight elevated card style
  //  boxShadow:1,paddingLeft:1, paddingRight:2, paddingY:0.75, borderRadius:1
  return (
    <Box sx={{ marginLeft: 1.5, marginTop: 1 }} className="center-height">
      <LocationOnIcon color="primary" />
      {mapLinkHandler ? (
        <Typography
          sx={{ fontSize: 14 }}
          color="primary"
          component="a"
          href={mapLinkHandler}
          target="_blank"
        >
          View on map
        </Typography>
      ) : (
        <Typography color="primary" sx={{ userSelect: 'none', fontSize: 14 }}>
          Location not captured
        </Typography>
      )}
    </Box>
  )
}

GoogleMap.propTypes = {
  mapLinkHandler: PropTypes.string,
}

GoogleMap.defaultProps = {
  mapLinkHandler: null,
  }

export default GoogleMap
