import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-unresolved
import Constants from 'utilities/Constants'

function CheckAuth() {
  const authUser = useSelector((store) => store.authUser)
  const location = useLocation()
  if (!authUser || authUser?.userId !== 0)
    return <Navigate to={Constants.ROUTE_INDEX_URL} state={{ from: location }} replace />
  return <Outlet />
}

export default CheckAuth
