/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import filter from '@inovua/reactdatagrid-community/filter'

import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/default-light.css'
// import './tmp-custom-orange.css'
import PropTypes from 'prop-types'


// export-file-view and functions in list imports
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import {
  Box,
  // Stack,
  // Divider,
  // Typography,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
} from '@mui/material'
// import Button from '@inovua/reactdatagrid-community/packages/Button'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// ...

import { setFilter, setPage } from '../../../store/listViewSlice'
import Constants from '../../../utilities/Constants'
import customFilterTypes from '../../../utilities/filterTypes'
import { exportPdfSettings } from '../../../configs/gridListSettings'
import { navigationConfig } from '../../navigation/navigationConfig'
import { setNavigationData } from '../../../store/navigationSlice'
// import AQHButton from '../AQHButton'

const gridStyle = { minHeight: 350 }
function AQHListView(props) {
  const dispatch = useDispatch()
  const {
    id,
    columns,
    data,
    style = {},
    defaultFilters = [],
    // exportGrid,
    selectable,
    loading,
    emptyText,
  } = props
  const [dataSource, setDataSource] = useState([])
  const [gridRef, setGridRef] = useState(null)

  const filters = useSelector((state) => state.listView.gridFilters)
  const storeData = filters.find((e) => e.id.toString() === id.toString())

  // selection management

  const [selectedValues, setSelectedValues] = useState({})
  const onSelectionChange = useCallback(({ selected }) => {
    setSelectedValues(selected)
    // console.log('Selected Values: ',selected)
  }, [])

  // const dataMap =
  //   gridRef && gridRef.current && gridRef.current.dataMap ? gridRef.current.dataMap : null

  // const toArray = (selected, dataMap) => {
  //   const keysObj = selected === true ? dataMap : selected;
  //   return Object.keys(keysObj).map(key => Number(key))
  // }

  // controlled pagination

  const skip = useSelector((state) => state.listView.skip)
  const currentSkip = skip.find((e) => e.id.toString() === id.toString())?.skip

  const [limit, setLimit] = useState(50)
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0)

  const setPageHandler = useCallback(
    (e) => {
      dispatch(setPage({ id, skip: e }))
      setCount(e)
    },
    [currentSkip],
  )

  useEffect(() => {
    setTimeout(() => {
      setPageHandler(currentSkip)
    }, 100)
  }, [])

  // exporting function content
  const notifyInfo = (toastMessage) => toast.info(toastMessage)

  const downloadBlob = (blob, fileName = 'grid-data.csv') => {
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.position = 'absolute'
    link.style.visibility = 'hidden'

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
  }

  const exportType = {
    currentCSV: 'currentCSV',
    allCSV: 'allCSV',
    currentExcel: 'currentExcel',
    allExcel: 'allExcel',
    currentPDF: 'currentPDF',
    allPDF: 'allPDF',
  }

  // console.log(gridRef.current)

  // navigation in competition, merchandising and offers
  const tmpCurrentGrid = window.location.pathname.split('/').at(-1)

  useEffect(() => {
    if (navigationConfig.competition === tmpCurrentGrid) {
      if (gridRef?.current?.dataSource.length > 0) {
        dispatch(
          setNavigationData({
            competition: gridRef.current.dataSource.map((e) => e?.competitionDataId),
          }),
        )
      }
    }
    else if (navigationConfig.merchandising === tmpCurrentGrid) {
      if (gridRef?.current?.dataSource.length > 0) {
        dispatch(
          setNavigationData({
            merchandising: gridRef.current.dataSource.map((e) => e?.mdDataId),
          }),
        )
      }
    }
    else if (navigationConfig.offers === tmpCurrentGrid) {
      if (gridRef?.current?.dataSource.length > 0) {
        dispatch(
          setNavigationData({
            offers: gridRef.current.dataSource.map((e) => e?.myOfferId),
          }),
        )
      }
    }
  })

  // eslint-disable-next-line no-unused-vars
  const exportFile = (type) => {
    const idName = id.split('View')[0]
    const columnsData = gridRef.current.visibleColumns
    const SEPARATOR = ','
    const header1 = columnsData.map((c) => c.name)
    const headers = columnsData.map((c) => c.header)?.filter((e) => e)
    header1.pop()
    let mappedHeaders = {}
    header1.forEach((e, i) => {
      mappedHeaders = { ...mappedHeaders, [e]: headers[i] }
    })

    // const header = header1.join(SEPARATOR)

    const rowsCurrentExcel = gridRef.current.dataSource.map((dataUnit) =>
      header1.reduce((prev, item) => ({ ...prev, [item]: dataUnit[item] }), {}),
    )
    const rowsAllExcel = data.map((dataUnit) =>
      header1.reduce((prev, item) => ({ ...prev, [item]: dataUnit[item] }), {}),
    )

    const rowsAllCSV = data.map((dataUnit) => header1.map((c) => dataUnit[c]).join(SEPARATOR))
    const rowsCurrentCSV = gridRef.current.dataSource.map((dataUnit) =>
      header1.map((c) => dataUnit[c]).join(SEPARATOR),
    )

    const rowsAllPDF = data.map((dataUnit) => header1.map((c) => dataUnit[c]))
    const rowsCurrentPDF = gridRef.current.dataSource.map((dataUnit) =>
      header1.map((c) => dataUnit[c]),
    )

    // custom headers for filtered export
    let customHeaderForFilter = storeData?.filters
      .map((item) => {
        if (item.value) {
          return `${mappedHeaders[item.name]} ${item.operator} '${item.value}'`
        }
        return null
      })
      .filter((e) => Boolean(e))
    customHeaderForFilter = customHeaderForFilter?.map((e, i) => {
      if (i === customHeaderForFilter.length - 1) {
        return `${e} :`
      }
      return `${e},`
    })

    // for excel custom headings
    function createGapRows(ws, nrows) {
      const ref = XLSX.utils.decode_range(ws['!ref']) // get original range
      // add to ending row
      ref.e.r += nrows
      // reassign row
      ws['!ref'] = XLSX.utils.encode_range(ref) // eslint-disable-line no-param-reassign
    }

    // head styling for pdf
    const headForPdf = headers.map((e) => ({
      content: e,
      styles: {
        minCellWidth: e.length * 2 + 2,
        fillColor: exportPdfSettings.headingBackgroundColor,
      },
    }))

    // ...

    let contents
    let workbook
    let worksheet
    let maxWidths
    let blob
    let doc

    notifyInfo(Constants.FILE_DOWNLOAD_STARTED)

    switch (type) {
      case exportType.allCSV:
        contents = [headers].concat(rowsAllCSV).join('\n')
        blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' })
        downloadBlob(blob, `${idName}.csv`)
        break

      case exportType.allExcel:
        worksheet = XLSX.utils.json_to_sheet(rowsAllExcel)
        workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, idName)

        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })
        /* calculate column width */
        maxWidths = header1.map((headerName) => ({
          wch: rowsCurrentExcel.reduce((w, r) => Math.max(w, r[headerName]?.length), 10) + 5,
        }))
        worksheet['!cols'] = maxWidths
        /* create an XLSX file and try to save to file.xlsx */
        XLSX.writeFile(workbook, `${idName}.xlsx`, { compression: true })
        break

      case exportType.currentCSV:
        contents = [headers].concat(rowsCurrentCSV).join('\n')
        blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' })
        downloadBlob(blob, `${idName}Custom.csv`)
        break

      case exportType.currentExcel:
        worksheet = XLSX.utils.json_to_sheet(rowsCurrentExcel)
        workbook = XLSX.utils.book_new()
        createGapRows(worksheet, 2)
        XLSX.utils.book_append_sheet(workbook, worksheet, idName)
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [[`// ${customHeaderForFilter?.join(' ')?.replace(' :', '.')}`]],
          { origin: -1 },
        )
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })
        maxWidths = header1.map((headerName) => ({
          wch: rowsCurrentExcel.reduce((w, r) => Math.max(w, r[headerName]?.length), 10) + 5,
        }))
        worksheet['!cols'] = maxWidths
        XLSX.writeFile(workbook, `${idName}.xlsx`, { compression: true })
        break

      case exportType.currentPDF:
        // eslint-disable-next-line new-cap
        doc = new jsPDF({
          orientation: 'landscape',
        })

        // custom header for filter values
        doc.setFontSize(10)
        doc.setFont('helvetica', 'normal')
        doc.text(15, 10, '[#]')
        // eslint-disable-next-line
        let prev = 20
        // eslint-disable-next-line
        const totalWidth = doc.internal.pageSize.getWidth()
        // eslint-disable-next-line
        let flagMore = false
        customHeaderForFilter?.forEach((e) => {
          if (doc.getTextWidth(e) + prev < totalWidth - 20) {
            doc.text(prev, 10, e)
            prev += doc.getTextWidth(e) + 1
          } else {
            flagMore = true
          }
        })
        if (flagMore) {
          doc.text(prev, 10, '...')
        }

        autoTable(doc, {
          head: [headForPdf],
          body: rowsCurrentPDF,
          didDrawPage: () => {
            doc.setFontSize(11)
            doc.setTextColor(90, 87, 87)
            doc.text(15, 205, exportPdfSettings.pdfFooterText)
          },
        })
        // eslint-disable-next-line
        //  const finalY = doc.lastAutoTable.finalY >200?doc.lastAutoTable.finalY :200
        //  doc.setTextColor(121,134,203)
        //  doc.text(15,finalY,'[#]  This is a system generated document.')

        doc.save(`document-${idName}Custom.pdf`)
        break

      case exportType.allPDF:
        // eslint-disable-next-line new-cap
        doc = new jsPDF({
          orientation: 'landscape',
        })

        // using jspdf-autotable and jspdf , conversion from json to pdf

        autoTable(doc, {
          head: [headForPdf],
          body: rowsAllPDF,
          didDrawPage: () => {
            doc.setFontSize(11)
            doc.setTextColor(90, 87, 87)
            doc.text(15, 205, exportPdfSettings.pdfFooterText)
          },
        })

        doc.save(`document-${idName}.pdf`)
        break

      default:
        throw new Error('export file function passed with unsupported argument')
    }
  }

  // const exportHandler = (type) => {
  //   notifyInfo(Constants.FILE_DOWNLOADING)
  //   setTimeout(() => {
  //     exportFile(type)
  //   }, 50)
  // }

  // ...

  // const defaultFilters = columns.map((e) => ({ name: e.name, type: e.type }));
  // const defaultFilters = [];

  useEffect(() => {
    const initialData = filter(data, storeData?.filters ?? defaultFilters, customFilterTypes)
    setDataSource(initialData)

    return () => {
      // destoryFilter(id);
    }
  }, [id, setDataSource, data, defaultFilters])

  const onFilterValueChange = useCallback(
    (filterValue) => {
      const filteredData = filter(data, filterValue, customFilterTypes)

      dispatch(setFilter({ id, filters: filterValue }))
      dispatch(setPage({ id, skip: 0 }))
      setCount(0)
      setDataSource(filteredData)
      // setting selections to empty object on filter change
      setSelectedValues({})
    },

    [setDataSource, data, dispatch, id],
  )

  return (
    <Box marginBottom={2}>
      <ReactDataGrid
        columns={columns}
        skip={currentSkip}
        limit={limit}
        onLimitChange={setLimit}
        onSkipChange={setPageHandler}
        filterable
        pagination
        dataSource={dataSource}
        style={{ ...gridStyle, ...style }}
        onFilterValueChange={onFilterValueChange}
        filterValue={storeData?.filters ?? defaultFilters}
        filterTypes={customFilterTypes}
        handle={setGridRef}
        selected={selectable ? selectedValues : null}
        onSelectionChange={selectable ? onSelectionChange : null}
        checkboxColumn={selectable}
        loading={loading}
        emptyText={emptyText}
      />
      {/* {exportGrid ? (
        <Box sx={{ marginTop: 2, marginBottom: '24px', width: '100%' }}>
          <Accordion sx={{ bgcolor: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="export-content"
              id="export-header"
              sx={{ height: '48px' }}
            >
              <Typography variant="h6" sx={{ fontWeight: '500' }} align="center">
                Exports
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Stack direction={{ md: 'row' }} gap={2} justifyContent="center">
                <Stack gap={2}>
                  <Typography align="center" color="#344767" sx={{ userSelect: 'none' }}>
                    Current Filtered Data
                  </Typography>
                  <Stack direction="row" gap={2} justifyContent="center">
                    <Button type="button" onClick={() => exportHandler(exportType.currentCSV)}>
                      Export CSV
                    </Button>
                    <Button type="button" onClick={() => exportHandler(exportType.currentExcel)}>
                      Export Excel
                    </Button>
                    <Button type="button" onClick={() => exportHandler(exportType.currentPDF)}>
                      Export PDF
                    </Button>
                  </Stack>
                </Stack>
                <hr
                  style={{
                    opacity: '0.5',
                    border: 'none',
                    borderTop: 'solid 0.5px',
                    borderRight: 'solid 0.5px',
                  }}
                />
                <Stack gap={2}>
                  <Typography align="center" color="#344767" sx={{ userSelect: 'none' }}>
                    Complete Data
                  </Typography>
                  <Stack direction="row" gap={2} justifyContent="center">
                    <Button type="button" onClick={() => exportHandler(exportType.allCSV)}>
                      Export CSV
                    </Button>
                    <Button type="button" onClick={() => exportHandler(exportType.allExcel)}>
                      Export Excel
                    </Button>
                    <Button type="button" onClick={() => exportHandler(exportType.allPDF)}>
                      Export PDF
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : null} */}
    </Box>
  )
}

AQHListView.QHtotype = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  exportGrid: PropTypes.bool,
  selectable: PropTypes.bool,
  loading: PropTypes.bool,
  emptyText: PropTypes.string,
}

AQHListView.defaultProps = {
  exportGrid: true,
  // selectable:true,
  selectable: false,
  loading: false,
  emptyText: 'No records available',
}

export default AQHListView
