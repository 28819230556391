import { useQuery, useMutation } from 'react-query'
import {
  getBackteamById,
  addBackteam,
  editBackteam,
} from './endPoints'


export const useGetClientUserById = ({ userId }) =>
  useQuery(['getUserById', userId], () => getBackteamById({ userId }), {
    refetchOnWindowFocus: false,
  })

export const useAddClientUser= () => useMutation(addBackteam)
export const useEditClientUser = () => useMutation(editBackteam)
