import React from 'react'
import ReactDOM from 'react-dom/client'
import platform from 'platform'
import axios from 'axios'
import moment from 'moment'

import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorGeneral from './views/errors/ErrorGeneral'
import App from './App'
import reportWebVitals from './reportWebVitals'

const errorHandler = async (error, errorInfo) => {
  const loggedInUser = JSON.parse(localStorage.getItem('persist:root') ?? 0)
  const userId = JSON.parse(loggedInUser?.authUser)?.userId ?? 0
  const ipRes = await axios.get('https://api.ipify.org/?format=json')
  const deviceIP = ipRes.data.ip
  const errorPacket = {
    userId,
    deviceIP,
    location: window.location.pathname,
    deviceScreen: `${window.screen.width} ${window.screen.height}`,
    deviceAvailScreen: `${window.screen.availWidth} ${window.screen.availHeight}`,
    deviceScreenDetails: `pixelDepth@${window.screen.pixelDepth} colorDepth@${window.screen.colorDepth} orientation@${window.screen.orientation.type}`,
    browser: platform.name,
    browserVersion: platform.version,
    platformModel: platform.product, // for mobile and tabs, popular vendors
    layout: platform.layout,
    os: `${platform.os.family} ${platform.os.version}, ${platform.os.architecture}bit`,
    description: platform.description,
    error: error.stack,
    errorInfo: errorInfo.componentStack,
    time: moment().format('DD/MM/YYYY H:mm'),
  }
// eslint-disable-next-line no-console
  console.error(errorPacket)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorGeneral} onError={errorHandler}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
