import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  organizationId: null,
  projectName:null,
  projectId:null
}
 
export const projectSlice = createSlice({
  name: 'projectDetails',
  initialState,
  reducers: {
    setProjectDetails: (state, action) => {
      const { organizationId, projectId, projectName } = action.payload
      return {organizationId, projectId, projectName}
    },
    // eslint-disable-next-line no-unused-vars
    resetProjectDetails: (state, action) => initialState,
  },
})

export const projectState = (state) => state.projectDetails

export const { setProjectDetails, resetProjectDetails } = projectSlice.actions

export default projectSlice.reducer
