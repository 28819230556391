import { useQuery, useMutation } from 'react-query'
import {
  getFieldTeamById,
  addFieldTeam,
  editFieldTeam,
  editFieldTeamPassword,
  deleteFieldTeam,
} from './endPoints'

export const useGetFieldTeamById = ({ fieldTeamId }) =>
  useQuery(['getFieldTeamById', fieldTeamId], () => getFieldTeamById({ fieldTeamId }), {
    refetchOnWindowFocus: false,
    cacheTime:0,
  })

export const useAddFieldTeam = () => useMutation(addFieldTeam)
export const useEditFieldTeam = () => useMutation(editFieldTeam)
export const useDeleteFieldTeam = () => useMutation(deleteFieldTeam)
export const useEditFieldTeamPassword = () => useMutation(editFieldTeamPassword)
