import { Navigate, useRoutes } from 'react-router-dom'

// eslint-disable-next-line import/no-unresolved
import Constants from 'utilities/Constants'
import DefaultLayout from './layouts/default/DefaultLayout'
import RequireAuth from './auth/RequireAuth'
import CheckAuth from './auth/CheckAuth'
import { Roles } from './auth'
import { publicRoutes } from './configs/routeConfigs'
// import { publicRoutes } from './configs/routeConfigsWithLazy'

export default function Router() {
  // eslint-disable-next-line no-lone-blocks
  {
    /* Looping through Routes Config file and also creating the auth roles that are accepted for the individual routes */
  }
 
  const routes = publicRoutes.map((publicRoute) => {
    const allowedAuth = publicRoute.auth.length > 0 ? publicRoute.auth : Object.values(Roles)
    let authWrapper = null

    // eslint-disable-next-line no-lone-blocks  
    // Protected is to allow the routes only when user logged in and session is running eg. Dashboard,Listing users etc.
    //  allowedRoles is to check what roles are allowed for that particular route as route is protected
    if (publicRoute.protected) authWrapper = <RequireAuth allowedRoles={allowedAuth} />

    // eslint-disable-next-line no-lone-blocks    
    // protectedNoAuth is for routes which should not be accesible upon login. eg. Logn, Register etc 
    if (publicRoute.protectedNoAuth) authWrapper = <CheckAuth />

    // eslint-disable-next-line no-lone-blocks
    // actualRoute is to define the route be it protected, protectedNoAuth and public 
    const actualRoute = {
      path: publicRoute.path,
      element: <publicRoute.component />,
    }

    // eslint-disable-next-line no-lone-blocks
    // wrapperRoute is to wrap the actual Route with with protected or protectedNoAuth,
    // so that the routes are now accessible with some condition
    const wrapperRoute = { element: authWrapper, children: [actualRoute] }

    // eslint-disable-next-line no-lone-blocks
    // protectedRoute is to created nested routes for protected routes so that 
    // default layout like Navbar, sidebar, footer etc will be always dispalyed for these routes
    const protectedRoute = {
      path: Constants.ROUTE_INDEX_URL,
      element: <DefaultLayout />,
      children: [wrapperRoute],
    }

    if (publicRoute.protected) return protectedRoute
    if (publicRoute.protectedNoAuth) return wrapperRoute

    return actualRoute
  })

  // eslint-disable-next-line no-lone-blocks
  {
    /* what ever the route that is not mentioned in the routes config will be handled by te below route and navigate to 404 Page */
  }
  routes.push({
    path: Constants.ROUTE_MISC_INDEX,
    element: <Navigate to={Constants.ROUTE_FALLBACK_URL} replace />,
  })

  return useRoutes(routes)
}
