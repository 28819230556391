import { useMutation } from 'react-query'
// eslint-disable-next-line import/no-unresolved
import apiURLs from '../../utilities/apiURLs'
import { request } from '../../utilities/axiosUtils'

const validateLogin = (credentials) =>
  request({
    url: apiURLs.login,
    method: apiURLs.postApiMethod,
    data: credentials,
    requiredToken: false,
  })

// eslint-disable-next-line import/prefer-default-export
export const useValidateLoginCredentials = () => useMutation(validateLogin)
