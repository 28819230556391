import {  Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { styled } from '@mui/material/styles'

export const TabStyled = styled(Tab)(() => ({
    fontSize: '12px',
    fontWeight: 500
}))
export const TabContextStyled = styled(TabContext)(() => ({}))
export const TabListStyled = styled(TabList)(() => ({
    
}))
export const TabPanelStyled = styled(TabPanel)(() => ({}))